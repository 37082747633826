import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const CashPaymentGuard = ({ children, profile, ...rest }) => {
  // const [isAuth, setIsAuth] = React.useState(false);
  const token = localStorage.getItem("token:key");

  let isFinanceHead = profile
    ? (profile &&
        profile.groups &&
        profile.groups.some(
          (grp) =>
            grp.name === "admin" ||
            (grp.name === "payer" && grp.module === "cash_advance")
        )) ||
      (profile && profile.division && profile.division.name === "Finance") ||
      (profile && profile.division && profile.division.code === "ACC")
    : false;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && isFinanceHead ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isFinanceHead ? location : "/dashboard",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

export default connect(mapStateToProps, {})(CashPaymentGuard);
