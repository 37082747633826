import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AdminProjectMobGuard = ({ children, profile, ...rest }) => {
    const token = localStorage.getItem("token:key");

    let isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin"
        )
        : false;

    let isProjectMobDivisions = profile
        ? profile &&
        profile.division &&
        (
            profile?.division?.code === "COM" || 
            profile?.division?.code === "FID" || 
            profile?.division?.code === "AMD" || 
            profile?.division?.code === "OPD" || 
            profile?.division?.code === "AID" || 
            profile?.division?.code === "SSD" || 
            profile?.division?.code === "DSD" || 
            profile?.division?.code === "PPM" || 
            profile?.division?.code === "QHS"
        )
        : false;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token && (isAdmin || isProjectMobDivisions) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: {
                                from: location,
                            },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

export default connect(mapStateToProps, {})(AdminProjectMobGuard);
