import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormControl,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    getPurchaseOrder,
    getPurchaseOrders,
    updatePurchaseOrder,
    submitPurchaseOrder
} from "../../../../actions/purchasingActions";
import { getCurrencies } from "../../../../actions/currencyActions";
import { getProfile } from "../../../../actions/userActions";
// import { formatCurrency } from "../../../utils";


// Procurement Division
import AcceptPOModal from "../modals/AcceptPOModal";
import DeclinePOModal from "../modals/DeclinePOModal";
import ApprovePOModal from "../modals/ApprovePOModal";
import RejectPOModal from "../modals/RejectPOModal";
import PayPOModal from "../modals/PayPOModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    vendor: "",
    month: "",
    year: "",
    division: "",
    purchaseReq: "",
    requisitionType: "",
    purchaseType:"",
    shippingType:"",
    purpose: "",
    dateNeeded: "",
    items: [],
    totalAmount: null,
    vatApplicable: "",
    vat: null,
    discount: "",
    serviceCharge: "",
    freightCharge: "",
    paymentTerms: "",
    totalVat: null,
    finalAmount: null,
};

const ViewPurchaseOrder = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openAccept, setOpenAccept] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openPay, setOpenPay] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, purchase_order } = useSelector((state) => state.po);
    const { currencies } = useSelector((state) => state.currency);


    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getPurchaseOrders());
        dispatch(getPurchaseOrder(id));
        dispatch(getCurrencies());
        dispatch(getProfile)
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (purchase_order && purchase_order.employee) || "",
            division: (purchase_order && purchase_order.division) || "",
            orderDate: (purchase_order && moment(purchase_order.orderDate).format("l")) || "",            
            month: (purchase_order && purchase_order.month) || "",
            year: (purchase_order && purchase_order.year) || "",
            vendor: (purchase_order && purchase_order.vendor) || "",
            purchaseReq: (purchase_order && purchase_order.purchaseReq) || "",

            currency: (purchase_order && purchase_order.currency) || "",
            creditTerms: (purchase_order && purchase_order.creditTerms) || "",
            paymentTerms: (purchase_order && purchase_order.paymentTerms) || "",
            
            totalQuantityNeeded: (purchase_order && purchase_order.totalQuantityNeeded) || "",
            totalAmount: (purchase_order && purchase_order.totalAmount) || "",
            cumPrice: (purchase_order && purchase_order.cumPrice) || "",
            finalAmount: (purchase_order && purchase_order.finalAmount) || "",

            items: (purchase_order && purchase_order.items) || [],
            status: (purchase_order && purchase_order.status) || "",
            documentNo: (purchase_order && purchase_order.documentNo) || "",

            vatApplicable: (purchase_order && purchase_order.vatApplicable) || "",
            vat: (purchase_order && purchase_order.vat) || "",
            discount: (purchase_order && purchase_order.discount) || "",
            serviceCharge: (purchase_order && purchase_order.serviceCharge) || "",
            freightCharge: (purchase_order && purchase_order.freightCharge) || "",

            createdBy: (purchase_order && purchase_order.createdBy) || "",
            createdAt: (purchase_order && purchase_order.createdAt) || "",

            submittedBy: (purchase_order && purchase_order.submittedBy) || "",
            submittedAt: (purchase_order && purchase_order.submittedAt) || "",

            approvedBy: (purchase_order && purchase_order.approvedBy) || "",
            approvedAt: (purchase_order && purchase_order.approvedAt) || "",
            approveComment: (purchase_order && purchase_order.approveComment) || "",
            
            rejectedBy: (purchase_order && purchase_order.rejectedBy) || "",
            rejectedAt: (purchase_order && purchase_order.rejectedAt) || "",
            rejectComment: (purchase_order && purchase_order.rejectComment) || "",

            acceptedBy: (purchase_order && purchase_order.acceptedBy) || "",
            acceptedAt: (purchase_order && purchase_order.acceptedAt) || "",
            acceptComment: (purchase_order && purchase_order.acceptComment) || "",

            declinedBy: (purchase_order && purchase_order.declinedBy) || "",
            declinedAt: (purchase_order && purchase_order.declinedAt) || "",
            declineComment: (purchase_order && purchase_order.declineComment) || "",

        }));
    }, [purchase_order]);

    useEffect(() => {
        if ((state.items && state.items.length > 0) || (state.discount > 0)) {
            let total2 = state.items.reduce((acc, cur) => { return acc + +cur.quotedAmount; }, 0);

            if (state.vatApplicable === "Yes") {
                let vat = (total2 * 0.075);
                setState((prev) => ({
                    ...prev,
                    totalAmount: ((total2 - vat) - state.discount),
                    vat: vat,
                }));
            } else if(state.vatApplicable === "No"){
                setState((prev) => ({
                    ...prev,
                    totalAmount: (total2 - state.discount),
                }));
            }
        }
    }, [state.items, state.vatApplicable, state.discount]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // procurement
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };
    // ceo
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // finance
    const handleOpenPay = () => {
        setOpenPay(true);
    };
    const handleClosePay = () => {
        setOpenPay(false);
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" || state.status === "declined" || state.status === "rejected"))
        : false;

    // capture item changes
    const handleItemChange = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            if(item.vatApplicable !== "Yes"){
                let vat = (item.extendedCost * 0.075);
                return {
                    ...item,
                    vat: vat,
                    vatExtendedCost: (item.extendedCost + vat),
                    [e.target.name]: e.target.value,
                };
            } else if(item.vatApplicable !== "No") {
                return {
                    ...item,
                    vat: null,
                    vatExtendedCost: null,
                    [e.target.name]: e.target.value,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityNeeded; }, 0);
            let total2 = state.items.reduce((acc, cur) => { return acc + +cur.extendedCost; }, 0);
            
            setState((prev) => ({
                ...prev,
                totalQuantityNeeded: total1,
                totalAmount: total2,
            }));
        }
    }, [state.items]);

    useEffect(() => {
        if(state.items && state.items.length > 0){
            let remItems = state.items.filter((item) => ((item.vatApplicable === "Yes") && (item.vat !== null)));
            
            if(remItems && remItems.length > 0){
                let totalVat = remItems.reduce((acc, cur) => { return acc + +cur.vat; }, 0);
                setState((prev) => ({
                    ...prev,
                    totalVat: totalVat,
                }));
            }else {
                setState((prev) => ({
                    ...prev,
                    totalVat: null,
                }));
            }
        }
    }, [state.items]);

    useEffect(() => {
        if(state.totalAmount && state.discount && state.freightCharge && state.serviceCharge){
            let part1 = (+state.serviceCharge + +state.freightCharge);

            let finalAmount = parseFloat((+state.totalAmount + (+part1)) - (+state.discount));

            if((state.totalVat !== null) || (state.totalVat !== "")){
                setState((prev) => ({
                    ...prev,
                    finalAmount: (+finalAmount + +state.totalVat),
                }));
            }else {
                setState((prev) => ({
                    ...prev,
                    finalAmount: finalAmount,
                }));
            }
        }
    }, [state.totalAmount, state.discount, state.freightCharge, state.serviceCharge, state.totalVat]);

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            items: state.items,
            discount: state.discount && state.discount,
            serviceCharge: state.serviceCharge && state.serviceCharge,
            freightCharge: state.freightCharge && state.freightCharge,
            totalAmount: state.totalAmount && state.totalAmount,
            finalAmount: state.finalAmount && state.finalAmount,
            paymentTerms: state.paymentTerms && state.paymentTerms,
            totalVat:  state.totalVat && state.totalVat,
            id
        }
        await dispatch(updatePurchaseOrder(data));
        setTimeout(async () => {
            await dispatch(getPurchaseOrder(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Order Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            items: state.items,
            discount: state.discount && state.discount,
            serviceCharge: state.serviceCharge && state.serviceCharge,
            freightCharge: state.freightCharge && state.freightCharge,
            totalAmount: state.totalAmount && state.totalAmount,
            finalAmount: state.finalAmount && state.finalAmount,
            paymentTerms: state.paymentTerms && state.paymentTerms,
            totalVat:  state.totalVat && state.totalVat,
            id,
            path: `purchasing/purchaseorder/view/${id}`
        };
        await dispatch(submitPurchaseOrder(data));
        await dispatch(getPurchaseOrder(id));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Purchase Order Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/purchasing/purchaseorder");
        }, 1500);
    };

    const isDivManager = profile
        ? profile &&
        (profile?.id === "5fb64accc521c80017c15e68")
        : false;

    const isCEO = profile
        ? profile &&
        profile.jobTitle &&
        profile?.jobTitle?.name === "Chief Executive Officer"
        : false;

    const isProcurement = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "PMG"
        : false;

    const isFinanceManager = profile
        ? profile &&
        profile.division &&
        ((profile?.division?.code === "FID") && (profile?.division?.manager === profile._id))
        : false;

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const validCheck = () => {
        let budgeted = state.items.map((item) => item.budgetAmount).reduce((prev, curr) => prev + curr, 0);
        let quoted = state.items.map((item) => item.quotedAmount).reduce((prev, curr) => prev + curr, 0);
        if(budgeted < 0 && quoted < 0){return true}else {return false}
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            {/* Procurement */}
            <AcceptPOModal {...{ openAccept, handleCloseAccept, id }} />
            <DeclinePOModal {...{ openDecline, handleCloseDecline, id }} />
            <ApprovePOModal {...{ openApprove, handleCloseApprove, id }} />
            <RejectPOModal {...{ openReject, handleCloseReject, id }} />

            <PayPOModal  {...{ openPay, handleClosePay, id }} />
            
            
            <Typography variant="overline" className={classes.title}>
                View Purchase Order
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/purchasing/purchaseorder")}
                    >
                        Back
                    </Button>
                    {((isProcurement || isAdmin) && (state.status === "accepted" || state.status === "authorized" || state.status === "approved")) ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 10 }}
                            className={classes.backBtn}
                            onClick={() => history.push(`/purchasing/purchaseorder/export/${id}`)}
                        >
                            View Document
                        </Button>
                    ) : null}

                    {((isFinanceManager || isAdmin) && (state.status === "approved")) ? (
                    <Button
                            color="primary"
                            variant="contained"
                            style={{ backgroundColor:'#093', marginLeft: 10 }}
                            className={classes.backBtn}
                            onClick={handleOpenPay}
                        >
                            Pay PO
                        </Button>
                    ) : null}

                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "authorized" || state.status === "approved") ? classes.statusGreen
                                        : (state.status === "issued" || state.status === "collected" || state.status === "transferred" || state.status === "returned" || state.status === "received" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmitHandler}>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Vendor / Supplier Name</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.vendor && state.vendor.companyName) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Vendor / Supplier Email</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.vendor && state.vendor.email) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Division/Subdivision</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.division && state.division.name) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Currency</label>
                        <FormControl fullWidth variant="outlined" required>
                            <Select
                                inputProps={{
                                    id: "currency",
                                    name: "currency",
                                }}
                                value={state.currency || ""}
                                disabled
                            >
                                <MenuItem disabled>Select Currency</MenuItem>
                                {currencies.map((currency) => (
                                    <MenuItem key={currency._id} value={currency.code}>
                                        {currency.code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Credit Terms</label>
                        <FormControl fullWidth variant="outlined" required>
                            <Select
                                inputProps={{
                                    id: "creditTerms",
                                    name: "creditTerms",
                                }}
                                value={state.creditTerms || ""}
                                disabled
                            >
                                <MenuItem disabled>Select Terms</MenuItem>
                                <MenuItem value="Advance">Advance</MenuItem>
                                <MenuItem value="14 Days">14 Days</MenuItem>
                                <MenuItem value="30 Days">30 Days</MenuItem>
                                <MenuItem value="45 Days">45 Days</MenuItem>
                                <MenuItem value="60 Days">60 Days</MenuItem>
                                <MenuItem value="See additional payment terms">See Additional Payment Terms</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {((state.creditTerms === "See additional payment terms") || (state.creditTerms === "See additional terms")) ? 
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Additional Payment Terms</label>
                        <TextField
                            id="paymentTerms"
                            name="paymentTerms"
                            placeholder="Additional Payment Terms"
                            value={state.paymentTerms || ""}
                            disabled={state.status !== "draft"}
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid> : null}
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Purchase Order Number</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={state.documentNo || ""}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Purchase Order Date</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={state.orderDate || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Requisition Number</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.purchaseReq && state.purchaseReq?.documentNo) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Month</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={(state.month && state.month) || ""}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Year</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={(state.year && state.year) || ""}
                            disabled
                            required
                        />
                    </Grid>
                </Grid>

                <Typography variant="overline" className={classes.title2}>
                    Purchase Requisition Details
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container spacing={2} className={classes.grid2}>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Requisition Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    inputProps={{
                                        id: "requisitionType",
                                        name: "requisitionType",
                                    }}
                                    disabled
                                    value={state.purchaseReq?.requisitionType || ""}
                                >
                                    <MenuItem value="Service">Service</MenuItem>
                                    <MenuItem value="Product">Product</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Shipping Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    labelId="shippingType"
                                    inputProps={{
                                        id: "shippingType",
                                        name: "shippingType",
                                    }}
                                    disabled
                                    value={state.purchaseReq?.shippingType || ""}
                                >
                                    <MenuItem value="Digital">Digital (Download)</MenuItem>
                                    <MenuItem value="Vendor">Vendor Delivery</MenuItem>
                                    <MenuItem value="Courier">Courier</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Purchase Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    labelId="purchaseType"
                                    inputProps={{
                                        id: "purchaseType",
                                        name: "purchaseType",
                                    }}
                                    disabled
                                    value={state.purchaseReq?.purchaseType || ""}
                                >
                                    <MenuItem value="Vendor">Vendor</MenuItem>
                                    <MenuItem value="Open Market">Open Market</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Vendor Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    labelId="vendorType"
                                    inputProps={{
                                        id: "vendorType",
                                        name: "vendorType",
                                    }}
                                    disabled
                                    value={state.purchaseReq?.vendorType || ""}
                                >
                                    <MenuItem value="Contract">Contract</MenuItem>
                                    <MenuItem value="Sole Source">Sole Source</MenuItem>
                                    <MenuItem value="Regular">Regular (3 Quotes)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>

                <Typography variant="overline" className={classes.title2}>
                    Purchase Order Items
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    {state.items ? state.items.map((sitem, i) => (
                        <Paper variant="outlined" square className={classes.paper} key={i}>
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                            >
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Product / Service Description</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="description"
                                        value={sitem.description || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <label style={{ fontWeight: "bold" }}>Quantity ({sitem.unit || ""})</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="text"
                                        name="quantityNeeded"
                                        value={`${sitem.quantityNeeded + ' ' + sitem.unit}` || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <label style={{ fontWeight: "bold" }}>Budget Amount</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="text"
                                        name="budgetAmount"
                                        value={((sitem && sitem.budgetAmount) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Quoted Price</label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            type="text"
                                            name="quotedAmount"
                                            value={((sitem && sitem.quotedAmount) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || ""}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Extended Cost</label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            type="text"
                                            name="extendedCost"
                                            value={((sitem && sitem.extendedCost) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || ""}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                            >
                                <Grid item xs={12} md={4}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        component="fieldset"
                                    >
                                        <FormLabel>Is VAT applicable?</FormLabel>
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio checked={(sitem && sitem.vatApplicable === "Yes") ? true : false} />}
                                                label="Yes"
                                                name="vatApplicable"
                                                onChange={handleItemChange(i)}
                                                disabled={!isCanEdit}
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio checked={(sitem && sitem.vatApplicable === "No") ? true : false} />}
                                                label="No"
                                                name="vatApplicable"
                                                onChange={handleItemChange(i)}
                                                disabled={!isCanEdit}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {(sitem && sitem.vatApplicable === "Yes") ? (
                                    <>
                                        <Grid item xs={12} md={2}>
                                            <label style={{ fontWeight: "bold" }}>VAT Applicable (7.5%):</label>
                                            <TextField
                                                placeholder="Applicable VAT"
                                                name="vat"
                                                value={((sitem && sitem.vat) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || "0"}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <label style={{ fontWeight: "bold" }}>Extended Cost + VAT:</label>
                                            <TextField
                                                placeholder="Extended Cost + VAT"
                                                name="vatExtendedCost"
                                                value={((sitem && sitem.vatExtendedCost) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || "0"}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                />
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Paper>
                    )) : <>
                        <Typography variant="overline" className={classes.title2}>
                            No Requested Items
                        </Typography>
                    </>}

                    {state.items && state.items.length > 0 ?
                        <Grid
                            container
                            spacing={2}
                            className={classes.grid}
                        >
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Discount:</label>
                                <TextField
                                    placeholder="Discount"
                                    name="discount"
                                    value={(state && state.discount) || ""}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={handleChange}
                                    disabled={!isCanEdit} 
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Service Charge:</label>
                                <TextField
                                    placeholder="Service Charge"
                                    name="serviceCharge"
                                    value={(state && state.serviceCharge) || ""}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={handleChange}
                                    disabled={!isCanEdit} 
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Freight Charge:</label>
                                <TextField
                                    placeholder="Freight Charge"
                                    name="freightCharge"
                                    value={(state && state.freightCharge) || ""}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={handleChange}
                                    disabled={!isCanEdit} 
                                />
                            </Grid>
                        </Grid>
                    : null}
                </Paper>

                <Grid container spacing={2} className={classes.grid}>
                    {(state.totalVat !== null ) ? (
                        <>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Total Applicable VAT:</label>
                                <TextField
                                    placeholder="Total Applicable VAT"
                                    name="vat"
                                    value={(state && state.totalVat) || ""}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </>
                    ) : <Grid item xs={12} md={8} />}
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Total Quantity of Items</label>
                        <TextField
                            placeholder="Total Quantity"
                            name="totalQuantityNeeded"
                            value={state.totalQuantityNeeded  || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Total Amount</label>
                        <TextField
                            placeholder="Total Amount"
                            name="totalAmount"
                            value={((state.totalAmount && state.totalAmount) || 0) || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={10} />
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Final Total Amount:</label>
                        <TextField
                            placeholder="Final Total Amount"
                            name="finalAmount"
                            value={((state.finalAmount && state.finalAmount) || 0) || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                    style={{ marginBottom: 5 }}
                >
                    <Grid item>
                        <Typography variant="overline" className={classes.title2}>
                            Audit Trail
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Icon className="fas fa-clipboard-list" color="disabled" />
                    </Grid>
                </Grid>
                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Prepared By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdAt}</span>
                            </Paper>
                        </Grid>

                        {(state.submittedAt && state.submittedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Reviewed By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedAt}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.acceptedAt && state.acceptedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Authorized By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.rejectedAt && state.rejectedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Rejected By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.approvedAt && state.approvedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Approved By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approveComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.declinedAt && state.declinedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Declined By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declineComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                {isCanEdit && (state.status === "draft" || state.status === "declined" || state.status === "rejected") ?  (
                    <Grid container justify="center">
                        <Grid item>
                            <ButtonGroup>
                                <Button
                                    className={classes.saveBtn}
                                    color="primary"
                                    variant="contained"
                                    disabled={loading || validCheck()}
                                    onClick={handleSave}
                                >
                                    {loading ? "Loading..." : "Save Changes"}
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.saveBtn2}
                                    variant="contained"
                                    onClick={handleSubmitHandler}
                                    disabled={loading || validCheck()}
                                >
                                    {loading ? "Loading..." : "Submit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}

                {/* ACTION BUTTONS */}
                {(isDivManager || isAdmin) && state.status === "submitted" ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        These actions are to be carried out by the Procurement Subdivision Manager.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenAccept}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Authorize
                                </Button>
                                <Button
                                    onClick={handleOpenDecline}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Decline
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}

                {(isCEO || isAdmin) && (state.status === "authorized" || state.status === "accepted") ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        This purchase order actions are to be carried out by the CEO.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenApprove}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Approve
                                </Button>
                                <Button
                                    onClick={handleOpenReject}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Reject
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}
             </form>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewPurchaseOrder;
