import React, { useState, useEffect, useCallback } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getDivisions } from "../../../../actions/divisionActions";

import {
    createPurchaseRequisition,
    getPurchaseRequisitions
} from "../../../../actions/purchasingActions";
// import { getDivisionMonthlyBudgetItems, getOneMonthlyBudgetItem } from "../../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines, getOneAnnualBudgetLineById } from "../../../../actions/annualAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    forWhom: "myself",
    month: "",
    year: "",
    division: "",
    requisitionGroup: "",
    purpose: "",
    dateNeeded: "",
    items: [],
    totalQuantityNeeded: null,
};

const INIT_ITEM = {
    description: "",
    quantityNeeded: 1,
    unit: "",
    budgetItem: "",
    budgetAmount: null,
}

const CreatePurchaseRequisitionModal = ({ openCreate, handleCloseCreate, getOneAnnualBudgetLineById, 
    createPurchaseRequisition, 
    // getDivisionMonthlyBudgetItems, getOneMonthlyBudgetItem, 
    getDivisionYearAnnualBudgetLines }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [dateNeeded, setDateNeeded] = useState(moment(new Date()).add(5, 'days'));
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.purchaseRequisition);
    // const { divItems, oneItem } = useSelector((state) => state.monthlyBudget);
    const { annualBudgetLines, annualBudget } = useSelector((state) => state.annualBudget);

    const { divisions } = useSelector((state) => state.division);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            dateNeeded: moment(dateNeeded).toDate(),
        }));
    }, [profile, dateNeeded, year, month]);

    const handleChangeDate = (date) => {
        setDateNeeded(date);
    };

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getDivisions());
    }, [dispatch]);

    // const getOneMonthlyBudgetItemCallback = useCallback(() => {
    //     if (item.budgetItem !== "") {
    //         getOneMonthlyBudgetItem({budgetItem: item.budgetItem});
    //     }
    // }, [getOneMonthlyBudgetItem, item]);

    // useEffect(() => {
    //     if (item.budgetItem !== "") {
    //         getOneMonthlyBudgetItemCallback();
    //     }
    // }, [item, getOneMonthlyBudgetItemCallback]);

    // const getDivisionMonthlyBudgetItemsCallback = useCallback(() => {
    //     if(state.division !==""){
    //         let data = {
    //             year: year && year,
    //             month: month && month,
    //             serviceDivision: state && state.division,
    //         };
    //         getDivisionMonthlyBudgetItems(data);
    //     }
    // }, [getDivisionMonthlyBudgetItems, state, year, month]);

    // useEffect(() => {
    //     if(state.division !==""){
    //         getDivisionMonthlyBudgetItemsCallback();
    //     }
    // }, [getDivisionMonthlyBudgetItemsCallback, state]);

    // useEffect(() => {
    //     if (oneItem) {
    //         setItem((prev) => ({
    //             ...prev,
    //             budgetAmount: oneItem && oneItem.amount,
    //         }));
    //     }
    // }, [oneItem]);

    // use annual budget
    const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
        if(state.division !==""){
            let data = {
                year: year && parseInt(year),
                division: state && state.division,
            };
            getDivisionYearAnnualBudgetLines(data);
        }
    }, [getDivisionYearAnnualBudgetLines, state, year]);

    useEffect(() => {
        if(state.division !==""){
            getDivisionYearAnnualBudgetLinesCallback();
        }
    }, [getDivisionYearAnnualBudgetLinesCallback, state]);

    const getOneAnnualBudgetLineByIdCallback = useCallback(() => {
        if (item.budgetItem !== "") {
            getOneAnnualBudgetLineById(item.budgetItem);
        }
    }, [getOneAnnualBudgetLineById, item]);

    useEffect(() => {
        if (item.budgetItem !== "") {
            getOneAnnualBudgetLineByIdCallback();
        }
    }, [item, getOneAnnualBudgetLineByIdCallback]);

    useEffect(() => {
        if (annualBudget.success) {
            setItem((prev) => ({
                ...prev,
                budgetAmount: annualBudget && annualBudget.data && annualBudget.data?.subTotal,
            }));
        }
    }, [annualBudget]);

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityNeeded; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantityNeeded: total1,
            }));
        }
    }, [state.items]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (item) => {
        if (item.quantityNeeded > "0" && item.description !== "" && item.budgetAmount !== "" && item.unit !== "" && item.budgetItem !== "") {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([item]),
            }));
            setItem(INIT_ITEM);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createPurchaseRequisition(data));
        });
        
        if (res) {
            dispatch(getPurchaseRequisitions());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Requisition Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/purchasing/requisition`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const isEnabled = profile
        ? profile &&
        profile.division &&
        (profile?.division?.code === "LAC" || profile?.division?.code === "PPM" || profile?.division?.code === "QHS" || 
        profile?.division?.code === "COM" || profile?.division?.code === "FAS" || profile?.division?.code === "SER" || 
        profile?.division?.code === "AID")
        : false;

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.purpose ||
        !state.requisitionGroup || 
        (state.items.length === 0) ||
        !state.dateNeeded ||
        (state.totalQuantityNeeded === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Purchase Requisition
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="EID"
                                            name="employeeId"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.employeeId || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} >
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>For Whom?</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "forWhom",
                                                    name: "forWhom",
                                                }}
                                                value={state.forWhom}
                                                onChange={handleChange}
                                                disabled={!isEnabled}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="myself">Myself</MenuItem>
                                                <MenuItem value="others" disabled={!isEnabled}>Other Subdivision</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {state.forWhom === "myself" ? 
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Division/Subdivision"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid> : 
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="division">Division/Subdivision</InputLabel>
                                            <Select
                                                labelId="division"
                                                label="Division/Subdivision"
                                                name="division"
                                                disabled={!isEnabled}
                                                value={state.division}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {divisions &&
                                                    divisions.map((item) => (
                                                        <MenuItem key={item._id} value={item._id} selected={item._id === profile.division._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>}
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Month"
                                            name="month"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.month && state.month) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Year"
                                            name="year"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.year && state.year) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date Needed"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={dateNeeded}
                                            minDate={dateNeeded}
                                            disablePast
                                            onChange={handleChangeDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} >
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Requisition Category</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "requisitionGroup",
                                                    name: "requisitionGroup",
                                                }}
                                                value={state.requisitionGroup}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="Regular">Regular Requisition</MenuItem>
                                                <MenuItem value="Asset Acquisition" disabled>Asset Acquisition</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={3}
                                            label="Detailed Description of the Purpose"
                                            name="purpose"
                                            value={state.purpose || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="overline" className={classes.title2}>
                                    Request for Items
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Typography variant="overline" className={classes.title2}>
                                        Please select from the approved budget items of your subdivision for the year <u>{year}</u>.<br />
                                        Click the green button to add.<br /> 
                                        There is no limit to the number of items you can add.
                                    </Typography>
                                </Paper>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {item ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined" required>
                                                    <InputLabel>Budget Item</InputLabel>
                                                    <Select
                                                            inputProps={{
                                                                id: "budgetItem",
                                                                name: "budgetItem",
                                                            }}
                                                            value={item.budgetItem}
                                                            onChange={handleItemChange}
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {annualBudgetLines &&
                                                                annualBudgetLines.map((stuff) => (
                                                                    <MenuItem key={stuff._id} value={stuff._id}>
                                                                        {stuff?.item?.name} {" - "} {stuff.subTotal && stuff.subTotal}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Budget Amount"
                                                    name="budgetAmount"
                                                    value={(item.budgetAmount && item.budgetAmount) || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description of Item"
                                                    name="description"
                                                    value={item.description || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity Needed"
                                                    type="number"
                                                    name="quantityNeeded"
                                                    value={item.quantityNeeded || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1} >
                                                <FormControl fullWidth variant="outlined" required>
                                                    <InputLabel>U of M</InputLabel>
                                                    <Select
                                                        inputProps={{
                                                            id: "unit",
                                                            name: "unit",
                                                        }}
                                                        value={item.unit}
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select Unit</MenuItem>
                                                        <MenuItem value="items">Items</MenuItem>
                                                        <MenuItem value="packets">Packets</MenuItem>
                                                        <MenuItem value="reams">Reams</MenuItem>
                                                        <MenuItem value="kg">Kg</MenuItem>
                                                        <MenuItem value="cartons">Cartons</MenuItem>
                                                        <MenuItem value="crates">Crates</MenuItem>
                                                        <MenuItem value="meters">Meters</MenuItem>
                                                        <MenuItem value="kilometers">Kilometers</MenuItem>
                                                        <MenuItem value="pieces">Pieces</MenuItem>
                                                        <MenuItem value="dozens">Dozens</MenuItem>
                                                        <MenuItem value="litres">Litres</MenuItem>
                                                        <MenuItem value="gallons">Gallons</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#093" }}
                                                    onClick={() => handleAddItemsArray(item)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* Items requested */}
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Requested Items
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {state.items ? state.items.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined" required>
                                                        <label style={{ fontWeight: "bold" }}>Budget Item</label>
                                                        <Select
                                                                inputProps={{
                                                                    id: "budgetItem",
                                                                    name: "budgetItem",
                                                                }}
                                                                value={sitem.budgetItem}
                                                                disabled
                                                            >
                                                                <MenuItem disabled>Select</MenuItem>
                                                                {annualBudgetLines &&
                                                                    annualBudgetLines.map((stuff) => (
                                                                        <MenuItem key={stuff._id} value={stuff._id}>
                                                                            {stuff?.item?.name} {" - "} {stuff.subTotal && stuff.subTotal}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Budget Amount</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="budgetAmount"
                                                        value={sitem.budgetAmount || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <label style={{ fontWeight: "bold" }}>Description</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="description"
                                                        value={sitem.description || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Needed</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        name="quantityNeeded"
                                                        value={sitem.quantityNeeded || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>U of M</label>
                                                        <Select
                                                            labelId="unit"
                                                            required
                                                            value={sitem.unit || ""}
                                                            name="unit"
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select Unit</MenuItem>
                                                            <MenuItem value="items">Items</MenuItem>
                                                            <MenuItem value="packets">Packets</MenuItem>
                                                            <MenuItem value="reams">Reams</MenuItem>
                                                            <MenuItem value="kg">Kg</MenuItem>
                                                            <MenuItem value="cartons">Cartons</MenuItem>
                                                            <MenuItem value="crates">Crates</MenuItem>
                                                            <MenuItem value="meters">Meters</MenuItem>
                                                            <MenuItem value="kilometers">Kilometers</MenuItem>
                                                            <MenuItem value="pieces">Pieces</MenuItem>
                                                            <MenuItem value="dozens">Dozens</MenuItem>
                                                            <MenuItem value="litres">Litres</MenuItem>
                                                            <MenuItem value="gallons">Gallons</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveItemsArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Requested Items
                                            </Typography>
                                        </>}
                                    </Paper>
                                </>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={9} />
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Total Quantity Needed"
                                            name="totalQuantityNeeded"
                                            value={state.totalQuantityNeeded || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createPurchaseRequisition, 
    // getDivisionMonthlyBudgetItems, getOneMonthlyBudgetItem, 
    getDivisionYearAnnualBudgetLines, getOneAnnualBudgetLineById,
})(CreatePurchaseRequisitionModal);
