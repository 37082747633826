import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import store from "./store";
import theme from "./theme";
import { getWeather } from "./utils/weather";

// layout
import Layout from "./components/layout/Layout";
import TwoFactorAuth from "./components/atoms/TwoFactorAuth";
import { Wrapper } from "./components/layout/Wrapper";
import { Splash } from "./components/layout/Splash";
import { Terms } from "./components/atoms/Terms";
import { Privacy } from "./components/atoms/Privacy";

// auth
import LoginComponent from "./components/auth/LoginComponent";
import ForgotPassword from "./components/auth/ForgotPassword";
import PasswordReset from "./components/auth/PasswordReset";
import RequestLink from "./components/auth/RequestLink";
import SetUserPassword from "./components/auth/SetUserPassword";
import SetVendorPassword from "./components/auth/SetVendorPassword";
import VendorRegister from "./components/auth/VendorRegister";
import VendorCompleteRegistration from "./components/auth/VendorCompleteRegistration";

// dashboard
import Dashboard from "./components/pages/dashboard/Dashboard";
import Notifications from "./components/pages/dashboard/Notifications";
import MyAccount from "./components/pages/account";
import { Quotes } from "./components/Admin/Quotes";
import CustomerQuotation from "./components/Admin/CustomerQuotation";
import CustomerFeedback from "./components/Admin/CustomerFeedback"

// guards
import AuthGuard from "./components/guards/AuthGuard";
import ProtectedGuard from "./components/guards/ProtectedGuard";
import AdminFinanceGuard from "./components/guards/AdminFinanceGuard";
import AdminQuotationGuard from "./components/guards/AdminQuotationGuard";
import AdminProjectMobGuard from "./components/guards/AdminProjectMobGuard";
import AdminCommercialGuard from "./components/guards/AdminCommercialGuard";
import AdminInventoryGuard from "./components/guards/AdminInventoryGuard";
import QHSEGuard from "./components/guards/QHSEGuard";
import CUEGuard from "./components/guards/CUEGuard";
import AdminQualityGuard from "./components/guards/AdminQualityGuard";
import PeoplesGuard from "./components/guards/PeoplesGuard";
import VendorGuard from "./components/guards/VendorGuard";
import AllGuard from "./components/guards/AllGuard";
import CashPaymentGuard from "./components/guards/CashPaymentGuard";
import PPMGuard from "./components/guards/PPMGuard";
import ChangeMgtGuard from "./components/guards/ChangeMgtGuard";

// setup
import CreateJobTitle from "./components/pages/setup/CreateJobTitle";
import Divisions from "./components/pages/setup/Divisions";
import UsersList from "./components/pages/setup/UsersList";
import Sequence from "./components/pages/setup/Sequence";
import Modules from "./components/pages/setup/Modules";
import SubModule from "./components/pages/setup/SubModule";
import CreateGroups from "./components/pages/setup/CreateGroups";
import UserAccess from "./components/pages/setup/UserAccess";

// asset acquisition
import AssetAcquisition from "./components/pages/assetAcquisition/AssetAcquisition";
import EditAssetAcquisition from "./components/pages/assetAcquisition/EditAssetAcquisition";
import ViewAssetAcquisition from "./components/pages/assetAcquisition/ViewAssetAcquisition";

// asset requisition
import AssetRequisition from "./components/pages/assetRequisition/AssetRequisition";
import EditAssetRequisition from "./components/pages/assetRequisition/EditAssetRequisition";
import ViewAssetRequisition from "./components/pages/assetRequisition/ViewAssetRequisition";

// purchasing
import PurchaseRequisition from "./components/pages/purchasing/purchaseRequisition/PurchaseRequisition";
import EditPurchaseRequisition from "./components/pages/purchasing/purchaseRequisition/EditPurchaseRequisition";
import ViewPurchaseRequisition from "./components/pages/purchasing/purchaseRequisition/ViewPurchaseRequisition";
import ReviewPurchaseRequisition from "./components/pages/purchasing/purchaseRequisition/ReviewPurchaseRequisition";
import PurchaseOrder from "./components/pages/purchasing/purchaseOrder/PurchaseOrder";
import ViewPurchaseOrder from "./components/pages/purchasing/purchaseOrder/ViewPurchaseOrder";
import PurchaseOrderPDF from "./components/pages/purchasing/purchaseOrder/PurchaseOrderPDF";
import RejectionLogs from "./components/pages/purchasing/RejectionLogs";
import OpenMarket from "./components/pages/purchasing/openMarket/OpenMarket";
import ViewOpenMarket from "./components/pages/purchasing/openMarket/ViewOpenMarket";
import RequestForQuote from "./components/pages/purchasing/requestForQuote/RequestForQuote";
import ViewRFQ from "./components/pages/purchasing/requestForQuote/ViewRFQ";
// import CreateRFQ from "./components/pages/purchasing/requestForQuote/CreateRFQ";

// budgets
import AllAnnual from "./components/pages/budgets/AllAnnual";
import CostCenter from "./components/pages/budgets/CostCenter";
import Monthly from "./components/pages/budgets/Monthly";
import MonthlyBudgetLine from "./components/pages/budgets/MonthlyBudgetLine";
import ViewMonthlyBudgetLine from "./components/pages/budgets/ViewMonthlyBudgetLine";
import ViewMonthlyBudget from "./components/pages/budgets/ViewMonthlyBudget";
import AllMonthly from "./components/pages/budgets/AllMonthly";
import AnnualBudgetReview from "./components/pages/budgets/AnnualBudgetReview";

import BudgetHead from "./components/pages/budgets/BudgetHead";
import BudgetSubhead from "./components/pages/budgets/BudgetSubhead";
import BudgetItem from "./components/pages/budgets/BudgetItem";
import Annual from "./components/pages/budgets/Annual";
import AnnualBudgetLine from "./components/pages/budgets/AnnualBudgetLine";
import ViewAnnualBudget from "./components/pages/budgets/ViewAnnualBudget";
import ViewAnnualBudgetLine from "./components/pages/budgets/ViewAnnualBudgetLine";
import EditBudgetLine from "./components/pages/budgets/EditBudgetLine";
import EditBudgetLineForm from "./components/pages/budgets/EditBudgetLineForm";
import EditMonthlyBudgetLineForm from "./components/pages/budgets/EditMonthlyBudgetLineForm";
import Currency from "./components/pages/budgets/Currency";
// import BudgetUtilizations from "./components/pages/budgets/BudgetUtilizations";

// quality
import Products from "./components/pages/quality/Products";
import VendorsList from "./components/pages/quality/VendorsList";

import ReceivingInspection from "./components/pages/quality/receivingInspection/Receiving";
import EditReceiving from "./components/pages/quality/receivingInspection/EditReceiving";
import ViewReceiving from "./components/pages/quality/receivingInspection/ViewReceiving";
import ReceivingPDF from "./components/pages/quality/receivingInspection/ReceivingPDF";

import WorkCompletion from "./components/pages/quality/workCompletion/WorkCompletion";
import EditWorkCompletion from "./components/pages/quality/workCompletion/EditWorkCompletion";
import ViewWorkCompletion from "./components/pages/quality/workCompletion/ViewWorkCompletion";
import WorkCompletionPDF from "./components/pages/quality/workCompletion/WorkCompletionPDF";

// cash advance
import CashAdvance from "./components/pages/cashAdvance/CashAdvance";
import CashAdvanceEdit from "./components/pages/cashAdvance/CashAdvanceEdit";
import ViewCashAdvance from "./components/pages/cashAdvance/ViewCashAdvance";
import RecoveryReview from "./components/pages/cashAdvance/RecoveryReview";
import RecoveryLog from "./components/pages/cashAdvance/RecoveryLog";
import ViewCashRecovery from "./components/pages/cashAdvance/ViewCashRecovery";
import ViewRecoveryLog from "./components/pages/cashAdvance/ViewRecoveryLog";
import Payment from "./components/pages/cashAdvance/Payment";
import ViewPayment from "./components/pages/cashAdvance/ViewPayment";
import Reimbursement from "./components/pages/cashAdvance/Reimbursement";
import EditReimbursement from "./components/pages/cashAdvance/EditReimbursement";
import ViewReimbursement from "./components/pages/cashAdvance/ViewReimbursement";
import ViewReimbursePayment from "./components/pages/cashAdvance/ViewReimbursePayment";

// ppm
import ChangeRequests from "./components/pages/ppm/change/ChangeRequests";
import EditChangeRequest from "./components/pages/ppm/change/EditChangeRequest";
import ViewChangeRequest from "./components/pages/ppm/change/ViewChangeRequest";

import ChangeImpacts from "./components/pages/ppm/change/ChangeImpacts";
import EditChangeImpact from "./components/pages/ppm/change/EditChangeImpact";
import ViewChangeImpact from "./components/pages/ppm/change/ViewChangeImpact";

import ChangeFieldForce from "./components/pages/ppm/change/ChangeFieldForce";
import EditFieldForce from "./components/pages/ppm/change/EditFieldForce";
import ViewFieldForce from "./components/pages/ppm/change/ViewFieldForce";

import Attendance from "./components/pages/ppm/Attendance";
import AttendanceReport from "./components/pages/ppm/AttendanceReport";
import ViewAttendance from "./components/pages/ppm/ViewAttendance";
import EmergencyList from "./components/pages/ppm/EmergencyList";
import Courses from "./components/pages/ppm/Courses";
import KPI from "./components/pages/ppm/KPI";
import AllUsersList from "./components/pages/ppm/AllUsersList";
import JobTitles from "./components/pages/ppm/JobTitles";
import ViewCourse from "./components/pages/ppm/ViewCourse";
import ViewLesson from "./components/pages/ppm/ViewLesson";
import AllCourses from "./components/pages/ppm/AllCourses";
import CourseView from "./components/pages/ppm/CourseView";
import LessonView from "./components/pages/ppm/LessonView";

// sales
import ServiceTypes from "./components/pages/sales/ServiceTypes";
import MasterList from "./components/pages/sales/MasterList";
import CWSR from "./components/pages/sales/cwsr/CWSR";
import EditCWSR from "./components/pages/sales/cwsr/EditCWSR";
import ReviewCWSR from "./components/pages/sales/cwsr/ReviewCWSR";
import ViewCWSR from "./components/pages/sales/cwsr/ViewCWSR";
import Quotation from "./components/pages/sales/quotations/Quotation";
import QuotationPDF from "./components/pages/sales/quotations/QuotationPDF";
import EditQuotation from "./components/pages/sales/quotations/EditQuotation";
import ViewQuotation from "./components/pages/sales/quotations/ViewQuotation";
import OrderDatabase from "./components/pages/sales/orderDatabase/OrderDatabase";
import ViewOrderDatabase from "./components/pages/sales/orderDatabase/ViewOrderDatabase";

// inventory
import Receipt from "./components/pages/inventory/receipt/Receipt";
import EditReceipt from "./components/pages/inventory/receipt/EditReceipt";
import ViewReceipt from "./components/pages/inventory/receipt/ViewReceipt";

import InventoryRequisition from "./components/pages/inventory/requisition/InventoryRequisition";
import EditRequisition from "./components/pages/inventory/requisition/EditRequisition";
import ViewRequisition from "./components/pages/inventory/requisition/ViewRequisition";
import RequisitionPDF from "./components/pages/inventory/requisition/RequisitionPDF";

import InventoryEquipments from "./components/pages/inventory/InventoryEquipments";
import Stores from "./components/pages/inventory/Stores";
import Offices from "./components/pages/inventory/Offices";
import Locations from "./components/pages/inventory/Locations";
import LocationItems from "./components/pages/inventory/LocationItems";
import StoreItems from "./components/pages/inventory/StoreItems";
import AssetDatabase from "./components/pages/inventory/AssetDatabase";
import InventoryCategories from "./components/pages/inventory/InventoryCategories";

import InventoryCount from "./components/pages/inventory/count/InventoryCount";
import ViewInventoryCount from "./components/pages/inventory/count/ViewInventoryCount";

import GatePass from './components/pages/inventory/gatepass/GatePass';
import EditGatePass from "./components/pages/inventory/gatepass/EditGatePass";
import ViewGatePass from "./components/pages/inventory/gatepass/ViewGatePass";

import GoodsReceivedNote from "./components/pages/inventory/goodsreceivednote/GoodsReceivedNote";
import EditGRN from "./components/pages/inventory/goodsreceivednote/EditGRN";
import ViewGRN from "./components/pages/inventory/goodsreceivednote/ViewGRN";
import ExportGRN from "./components/pages/inventory/goodsreceivednote/ExportGRN";

// project mobilization
import ProjectMobDemob from './components/pages/projectMob/ProjectMobDemob';
import ViewProjectMobDemob from './components/pages/projectMob/ViewProjectMob';
import JobAttendance from './components/pages/projectMob/JobAttendance';
import ViewJobAttendance from './components/pages/projectMob/ViewProjectMob';
import WeeklyActivity from './components/pages/projectMob/WeeklyActivity';
import ViewWeeklyActivity from './components/pages/projectMob/ViewProjectMob';

// crm
import Customers from "./components/pages/crm/Customers";
import Contacts from "./components/pages/crm/Contacts";
import Leads from "./components/pages/crm/Leads";
import ViewLead from "./components/pages/crm/ViewLead";
import SalesCall from "./components/pages/crm/SalesCall";
import ViewSalesCall from "./components/pages/crm/ViewSalesCall";

// visitors
import VisitorRequests from "./components/pages/visitor/VisitorRequests";
import VisitorLog from "./components/pages/visitor/VisitorLog";
import ViewVisitor from "./components/pages/visitor/ViewVisitor";
import CheckInVisitor from "./components/pages/visitor/CheckInVisitor";
import CheckOutVisitor from "./components/pages/visitor/CheckOutVisitor";

function App() {
  const [loading, setLoading] = useState(true);
  const [theming, setTheme] = useState(theme);
  const [geolocation, setGeoLocation] = React.useState({
    lat: "",
    lon: "",
  });
  const [weather, setWeather] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);

  const geoCallback = React.useCallback(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setGeoLocation((prev) => ({
        ...prev,
        lon: pos.coords.longitude,
        lat: pos.coords.latitude,
      }));
    });
  }, []);

  React.useEffect(() => {
    geoCallback();
  }, [geoCallback]);

  const weatherCallback = React.useCallback(() => {
    getWeather(geolocation.lat, geolocation.lon)
      .then((doc) => {
        setWeather(doc);
      })
      .catch((err) => console.error(err));
  }, [geolocation]);

  React.useEffect(() => {
    weatherCallback();
  }, [weatherCallback]);

  // console.log(weather);

  useEffect(() => {
    Cookies.set("splash", String(Math.random()), {
      expires: 7,
      path: "/",
      sameSite: "strict",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const toggleDarkTheme = () => {
    let newPaletteType = theming.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        type: newPaletteType,
      },
      typography: {
        // fontFamily: "Quicksand",
        fontFamily: "Rubik",
      },
    });
    localStorage.setItem("theme", newPaletteType);

    setClicked((prev) => {
      localStorage.setItem("clicked", JSON.stringify(!prev));
      return !prev;
    });
  };

  const muiTheme = createMuiTheme(theming);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Provider store={store}>
        <Wrapper>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                component={
                  loading && !Cookies.get("splash") ? Splash : LoginComponent
                }
              />
              <Route path="/security/twofactor/:id" component={TwoFactorAuth} />
              <Route path="/login" component={LoginComponent} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/forgotpass" component={ForgotPassword} />
              <Route path="/vendor/requestlink" component={RequestLink} />
              <Route
                path="/setuserpassword/:token"
                component={SetUserPassword}
              />
              <Route
                path="/setvendorpassword/:token"
                component={SetVendorPassword}
              />
              <Route path="/resetpassword/:token" component={PasswordReset} />
              <Route exact path="/vendor/register" component={VendorRegister} />
              <Route
                path="/vendor/completereg/:token"
                component={VendorCompleteRegistration}
              />
              <Route path="/customerquotation/:id" component={CustomerQuotation} />
              <Route path="/customerfeedback/:id" component={CustomerFeedback} />

              <>
                {/* <VendorLayout
                  toggleDarkTheme={toggleDarkTheme}
                  weather={weather}
                >
                  <Route exact path="/vendors" component={VendorDashboard} />
                  <Route
                    exact
                    path="/vendors/account"
                    component={VendorAccount}
                  />
                </VendorLayout> */}

                <Layout
                  toggleDarkTheme={toggleDarkTheme}
                  clicked={clicked}
                  weather={weather}
                >
                  <AuthGuard path="/dashboard">
                    <Dashboard weather={weather} />
                  </AuthGuard>

                  <AuthGuard exact path="/visitor/appointments">
                    <VisitorRequests />
                  </AuthGuard>
                  <AuthGuard path="/visitor/view/:id">
                    <ViewVisitor />
                  </AuthGuard>
                  <CUEGuard exact path="/visitor/all">
                    <VisitorLog />
                  </CUEGuard>
                  <AuthGuard exact path="/visitor/checkin">
                    <CheckInVisitor />
                  </AuthGuard>
                  <AuthGuard exact path="/visitor/checkout">
                    <CheckOutVisitor />
                  </AuthGuard>
                  <QHSEGuard exact path="/emergencylist">
                    <EmergencyList />
                  </QHSEGuard>

                  <AuthGuard path="/mycourses">
                    <AllCourses />
                  </AuthGuard>
                  <AuthGuard exact path="/course/:id">
                    <CourseView />
                  </AuthGuard>
                  <AuthGuard exact path="/lesson/:id">
                    <LessonView />
                  </AuthGuard>

                  <AuthGuard path="/kpi">
                    <KPI />
                  </AuthGuard>

                  <VendorGuard path="/vendors/quote">
                    <Quotes />
                  </VendorGuard>

                  <AllGuard path="/account">
                    <MyAccount />
                  </AllGuard>

                  <AdminQualityGuard exact path="/quality/vendors">
                    <VendorsList />
                  </AdminQualityGuard>
                  <AdminQualityGuard exact path="/quality/products">
                    <Products />
                  </AdminQualityGuard>
                  
                  <AuthGuard exact path="/quality/receivinginspection">
                    <ReceivingInspection />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/edit/:id">
                    <EditReceiving />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/view/:id">
                    <ViewReceiving />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/export/:id">
                    <ReceivingPDF />
                  </AuthGuard>

                  <AuthGuard exact path="/quality/workcompletion">
                    <WorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/edit/:id">
                    <EditWorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/view/:id">
                    <ViewWorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/export/:id">
                    <WorkCompletionPDF />
                  </AuthGuard>
                  

                  

                  <AdminFinanceGuard path="/budget/head">
                    <BudgetHead />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/budget/subhead">
                    <BudgetSubhead />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/budget/item">
                    <BudgetItem />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard exact path="/budget/all/annual">
                    <AllAnnual />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard exact path="/budget/all/monthly">
                    <AllMonthly />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/currency">
                    <Currency />
                  </AdminFinanceGuard>
                  
                  {/* <AuthGuard path="/budget/utilization">
                    <BudgetUtilizations />
                  </AuthGuard> */}

                  <AdminFinanceGuard path="/costcenter">
                    <CostCenter />
                  </AdminFinanceGuard>

                  <AdminFinanceGuard path="/budget/all/review">
                    <AnnualBudgetReview />
                  </AdminFinanceGuard>

                  <AuthGuard exact path="/budget/annual">
                    <Annual />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/annual/budgetline/:id">
                    <AnnualBudgetLine />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/budgetline/view/:id">
                    <ViewAnnualBudgetLine />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/annual/budgetline/edit/:id">
                    <EditBudgetLine />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/budgetline/edit/form/:id">
                    <EditBudgetLineForm />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/view/:id">
                    <ViewAnnualBudget />
                  </AuthGuard>

                  <AuthGuard exact path="/budget/monthly">
                    <Monthly />
                  </AuthGuard>

                  <AuthGuard exact path="/budget/monthly/budgetline/:id">
                    <MonthlyBudgetLine />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/monthly/budgetline/view/:id">
                    <ViewMonthlyBudgetLine />
                  </AuthGuard>

                  <AuthGuard path="/budget/monthly/budgetline/edit/:id">
                    <EditMonthlyBudgetLineForm />
                  </AuthGuard>

                  <AuthGuard path="/budget/monthly/view/:id">
                    <ViewMonthlyBudget />
                  </AuthGuard>

                  <AuthGuard exact path="/cashadvance">
                    <CashAdvance />
                  </AuthGuard>

                  <AuthGuard exact path="/cashadvance/:id">
                    <CashAdvanceEdit />
                  </AuthGuard>

                  <CashPaymentGuard exact path="/cash/payment">
                    <Payment />
                  </CashPaymentGuard>

                  <CashPaymentGuard exact path="/recovery/review">
                    <RecoveryReview />
                  </CashPaymentGuard>

                  <CashPaymentGuard path="/recovery/view/:id">
                    <ViewCashRecovery />
                  </CashPaymentGuard>

                  <PeoplesGuard exact path="/recovery/log">
                    <RecoveryLog />
                  </PeoplesGuard>

                  <PeoplesGuard path="/recovery/log/view/:id">
                    <ViewRecoveryLog />
                  </PeoplesGuard>

                  <CashPaymentGuard exact path="/cash/payment/:id">
                    <ViewPayment />
                  </CashPaymentGuard>

                  <CashPaymentGuard path="/cash/reimbursement/payment/:id">
                    <ViewReimbursePayment />
                  </CashPaymentGuard>

                  <AuthGuard path="/cashadvance/view/:id">
                    <ViewCashAdvance />
                  </AuthGuard>

                  <AuthGuard exact path="/reimbursement">
                    <Reimbursement />
                  </AuthGuard>
                  <AuthGuard exact path="/reimbursement/:id">
                    <EditReimbursement />
                  </AuthGuard>

                  <AuthGuard path="/reimbursement/view/:id">
                    <ViewReimbursement />
                  </AuthGuard>

                  <AuthGuard exact path="/asset/acquisition">
                    <AssetAcquisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/acquisition/edit/:id">
                    <EditAssetAcquisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/acquisition/view/:id">
                    <ViewAssetAcquisition />
                  </AuthGuard>

                  <AuthGuard exact path="/asset/requisition">
                    <AssetRequisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/requisition/edit/:id">
                    <EditAssetRequisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/requisition/view/:id">
                    <ViewAssetRequisition />
                  </AuthGuard>

                  

                  <AuthGuard exact path="/purchasing/requisition">
                    <PurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard path="/purchasing/requisition/edit/:id">
                    <EditPurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/requisition/view/:id">
                    <ViewPurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard path="/purchasing/requisition/review/:id">
                    <ReviewPurchaseRequisition />
                  </AuthGuard>

                  <AuthGuard exact path="/notifications">
                    <Notifications />
                  </AuthGuard>

                  <AuthGuard path="/purchasing/requestforquote">
                    <RequestForQuote />
                  </AuthGuard>
                  {/* <AuthGuard path="/purchasing/rfqs">
                    <CreateRFQ />
                  </AuthGuard> */}
                  <AuthGuard exact path="/purchasing/rfq/view/:id">
                    <ViewRFQ />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/openmarket/view/:id">
                    <ViewOpenMarket />
                  </AuthGuard>

                  <AuthGuard exact path="/purchasing/purchaseorder">
                    <PurchaseOrder />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/purchaseorder/view/:id">
                    <ViewPurchaseOrder />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/purchaseorder/export/:id">
                    <PurchaseOrderPDF />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/rejectionlogs">
                    <RejectionLogs />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/openmarket">
                    <OpenMarket />
                  </AuthGuard>

                  <ProtectedGuard path="/setup/access">
                    <UserAccess />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/jobtitle">
                    <CreateJobTitle />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/groups">
                    <CreateGroups />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/division">
                    <Divisions />
                  </ProtectedGuard>

                  <ProtectedGuard exact path="/setup/users">
                    <UsersList />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/sequence">
                    <Sequence />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/modules">
                    <Modules />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/module/submodule/:id">
                    <SubModule />
                  </ProtectedGuard>
                  <ProtectedGuard exact path="/setup/offices">
                    <Offices />
                  </ProtectedGuard>

                  <ChangeMgtGuard exact path="/change/requests">
                    <ChangeRequests />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/request/edit/:id">
                    <EditChangeRequest />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/request/view/:id">
                    <ViewChangeRequest />
                  </ChangeMgtGuard>

                  <ChangeMgtGuard exact path="/change/impact">
                    <ChangeImpacts />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/impact/edit/:id">
                    <EditChangeImpact />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/impact/view/:id">
                    <ViewChangeImpact />
                  </ChangeMgtGuard>

                  <ChangeMgtGuard exact path="/change/fieldforce">
                    <ChangeFieldForce />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/fieldforce/edit/:id">
                    <EditFieldForce />
                  </ChangeMgtGuard>
                  <ChangeMgtGuard exact path="/change/fieldforce/view/:id">
                    <ViewFieldForce />
                  </ChangeMgtGuard>

                  <PPMGuard exact path="/attendance/list">
                    <Attendance />
                  </PPMGuard>
                  <PPMGuard exact path="/attendance/report">
                    <AttendanceReport />
                  </PPMGuard>

                  <PPMGuard exact path="/attendance/view/:id">
                    <ViewAttendance />
                  </PPMGuard>

                  <PPMGuard path="/employee/jobtitles">
                    <JobTitles />
                  </PPMGuard>
                  <PPMGuard path="/employee/list">
                    <AllUsersList />
                  </PPMGuard>

                  <PPMGuard exact path="/lms/courses">
                    <Courses />
                  </PPMGuard>
                  <PPMGuard exact path="/lms/course/:id">
                    <ViewCourse />
                  </PPMGuard>
                  <PPMGuard exact path="/lms/lesson/:id">
                    <ViewLesson />
                  </PPMGuard>

                  {/* INVENTORY STARTS */}
                  <AdminInventoryGuard exact path="/inventory/stores">
                    <Stores />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/locations">
                    <Locations />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/locationitems/:id">
                    <LocationItems />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/storeitems">
                    <StoreItems />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/categories">
                    <InventoryCategories />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/count">
                    <InventoryCount />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard path="/inventory/count/view/:id">
                    <ViewInventoryCount />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/assetdatabase">
                    <AssetDatabase />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn">
                    <GoodsReceivedNote />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn/edit/:id">
                    <EditGRN />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn/view/:id">
                    <ViewGRN />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn/export/:id">
                    <ExportGRN />
                  </AdminInventoryGuard>


                  <AuthGuard exact path="/inventory/requisition">
                    <InventoryRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/requisition/edit/:id">
                    <EditRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/requisition/view/:id">
                    <ViewRequisition />
                  </AuthGuard>
                  <AdminInventoryGuard exact path="/inventory/requisition/export/:id">
                    <RequisitionPDF />
                  </AdminInventoryGuard>
                  <AuthGuard exact path="/inventory/equipments">
                    <InventoryEquipments />
                  </AuthGuard>

                  <AuthGuard exact path="/inventory/receipt">
                    <Receipt />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/receipt/edit/:id">
                    <EditReceipt />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/receipt/view/:id">
                    <ViewReceipt />
                  </AuthGuard>

                  <AuthGuard exact path="/inventory/gatepass">
                    <GatePass />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/gatepass/edit/:id">
                    <EditGatePass />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/gatepass/view/:id">
                    <ViewGatePass />
                  </AuthGuard>

                  {/* INVENTORY ENDS */}

                  <AuthGuard exact path="/sales/cwsr">
                    <CWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/:id">
                    <EditCWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/review/:id">
                    <ReviewCWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/view/:id">
                    <ViewCWSR />
                  </AuthGuard>

                  <AuthGuard exact path="/sales/pricelist">
                    <MasterList />
                  </AuthGuard>

                  <AdminCommercialGuard exact path="/sales/orderdatabase">
                    <OrderDatabase />
                  </AdminCommercialGuard>

                  <AdminCommercialGuard exact path="/sales/orderdatabase/view/:id">
                    <ViewOrderDatabase />
                  </AdminCommercialGuard>

                  

                  <AdminQuotationGuard exact path="/sales/quotation">
                    <Quotation />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/:id">
                    <QuotationPDF />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/view/:id">
                    <ViewQuotation />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/edit/:id">
                    <EditQuotation />
                  </AdminQuotationGuard>

                  <AuthGuard path="/crm/customers">
                    <Customers />
                  </AuthGuard>

                  <AuthGuard path="/sales/service">
                    <ServiceTypes />
                  </AuthGuard>

                  <AuthGuard path="/crm/contacts">
                    <Contacts />
                  </AuthGuard>

                  <AuthGuard exact path="/crm/leads">
                    <Leads />
                  </AuthGuard>
                  <AuthGuard path="/crm/leads/:id">
                    <ViewLead />
                  </AuthGuard>
                  <AuthGuard exact path="/crm/salescall">
                    <SalesCall />
                  </AuthGuard>
                  <AuthGuard path="/crm/salescall/:id">
                    <ViewSalesCall />
                  </AuthGuard>

                  <AdminProjectMobGuard exact path="/pml/mobdemob">
                    <ProjectMobDemob />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/mobdemob/view/:id">
                    <ViewProjectMobDemob />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard exact path="/pml/jobattendance">
                    <JobAttendance />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/jobattendance/view/:id">
                    <ViewJobAttendance />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard exact path="/pml/weeklyactivity">
                    <WeeklyActivity />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/weeklyactivity/view/:id">
                    <ViewWeeklyActivity />
                  </AdminProjectMobGuard>


                </Layout>
              </>
            </Switch>
          </Router>
        </Wrapper>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
