import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    getAllStores,
    getLocations,
    getRequisition,
    getAllInventoryItems,
} from "../../../../actions/inventoryActions";
// Division Manager Accept / Decline Requisition
import AcceptRequisitionModal from "../modals/RequisitionModals/AcceptRequisitionModal";
import DeclineRequisitionModal from "../modals/RequisitionModals/DeclineRequisitionModal";

// Solutions // QHSE Consent, Collect / Refuse, Leave Requisition
import ConsentRequisitionModal from "../modals/RequisitionModals/ConsentRequisitionModal";
import RefuseRequisitionModal from "../modals/RequisitionModals/RefuseRequisitionModal";
import CollectRequisitionModal from "../modals/RequisitionModals/CollectRequisitionModal";
import LeaveRequisitionModal from "../modals/RequisitionModals/LeaveRequisitionModal";

// Inventory Manager Approve / Reject / Issue Requisition
import ConfirmRequisitionModal from "../modals/RequisitionModals/ConfirmRequisitionModal";
import RejectRequisitionModal from "../modals/RequisitionModals/RejectRequisitionModal";
import IssueRequisitionModal from "../modals/RequisitionModals/IssueRequisitionModal";
import CancelRequisitionModal from "../modals/RequisitionModals/CancelRequisitionModal";
import TransferRequisitionModal from "../modals/RequisitionModals/TransferRequisitionModal";
import CompleteRequisitionModal from "../modals/RequisitionModals/CompleteRequisitionModal";

// Division Representative Receives / Return items
import ReceiveRequisitionModal from "../modals/RequisitionModals/ReceiveRequisitionModal";
import ReturnRequisitionModal from "../modals/RequisitionModals/ReturnRequisitionModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    purpose: "",
    mainCategory: "",
    dateNeeded: "",
    store: "",
    locationFrom: "",
    locationTo: "",
    items: [],
    totalQuantityRequested: '',
};

const ViewRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openAccept, setOpenAccept] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const [openConsent, setOpenConsent] = useState(false);
    const [openRefuse, setOpenRefuse] = useState(false);
    const [openCollect, setOpenCollect] = useState(false);
    const [openLeave, setOpenLeave] = useState(false);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openIssue, setOpenIssue] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openTransfer, setOpenTransfer] = useState(false);

    const [openReceive, setOpenReceive] = useState(false);
    const [openReturn, setOpenReturn] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { locations } = useSelector((state) => state.location);
    const { invItems } = useSelector((state) => state.inventoryItem);
    const { allstores } = useSelector((state) => state.stores);
    const { error, loading, requisition } = useSelector((state) => state.requisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getAllStores());
        dispatch(getAllInventoryItems());
        dispatch(getRequisition(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (requisition && requisition.purpose) || "",
            employee: (requisition && requisition.employee) || "",
            division: (requisition && requisition.division) || "",
            dateNeeded: (requisition && moment(requisition.dateNeeded).format("l")) || "",
            store: (requisition && requisition.store?.id) || "",
            locationFrom: (requisition && requisition.locationFrom) || "",
            mainCategory: (requisition && requisition.mainCategory) || "Others",
            locationTo: (requisition && requisition.locationTo) || "",
            totalQuantityRequested: (requisition && requisition.totalQuantityRequested) || "",

            items: (requisition && requisition.items) || [],
            status: (requisition && requisition.status) || "",
            documentNo: (requisition && requisition.documentNo) || "",

            submittedBy: (requisition && requisition.submittedBy && requisition.submittedBy.userName) || "",
            submittedAt: (requisition && requisition.submittedAt) || "",

            acceptedBy: (requisition && requisition.acceptedBy) || "",
            acceptedAt: (requisition && requisition.acceptedAt) || "",
            acceptComment: (requisition && requisition.acceptComment) || "",

            consentedBy: (requisition && requisition.consentedBy) || "",
            consentedAt: (requisition && requisition.consentedAt) || "",
            consentComment: (requisition && requisition.consentComment) || "",

            approvedBy: (requisition && requisition.approvedBy) || "",
            approvedAt: (requisition && requisition.approvedAt) || "",
            approverComment: (requisition && requisition.approverComment) || "",

            confirmedBy: (requisition && requisition.confirmedBy) || "",
            confirmedAt: (requisition && requisition.confirmedAt) || "",
            confirmComment: (requisition && requisition.confirmComment) || "",

            issuedBy: (requisition && requisition.issuedBy) || "",
            issuedAt: (requisition && requisition.issuedAt) || "",
            issueComment: (requisition && requisition.issueComment) || "",

            collectedBy: (requisition && requisition.collectedBy) || "",
            collectedAt: (requisition && requisition.collectedAt) || "",
            collectComment: (requisition && requisition.collectComment) || "",

            transferredBy: (requisition && requisition.transferredBy) || "",
            transferredAt: (requisition && requisition.transferredAt) || "",
            transferComment: (requisition && requisition.transferComment) || "",

            receivedBy: (requisition && requisition.receivedBy) || "",
            receivedAt: (requisition && requisition.receivedAt) || "",
            receiveComment: (requisition && requisition.receiveComment) || "",

            returnedBy: (requisition && requisition.returnedBy) || "",
            returnedAt: (requisition && requisition.returnedAt) || "",
            returnComment: (requisition && requisition.returnComment) || "",

            completedBy: (requisition && requisition.completedBy) || "",
            completedAt: (requisition && requisition.completedAt) || "",
            completeComment: (requisition && requisition.completeComment) || "",

        }));
    }, [requisition]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // solutions (it equipments) // QHSE (ppe)
    const handleOpenConsent = () => {
        setOpenConsent(true);
    };
    const handleCloseConsent = () => {
        setOpenConsent(false);
    };
    const handleOpenRefuse = () => {
        setOpenRefuse(true);
    };
    const handleCloseRefuse = () => {
        setOpenRefuse(false);
    };
    const handleOpenCollect = () => {
        setOpenCollect(true);
    };
    const handleCloseCollect = () => {
        setOpenCollect(false);
    };
    const handleOpenLeave = () => {
        setOpenLeave(true);
    };
    const handleCloseLeave = () => {
        setOpenLeave(false);
    };

    // inventory store
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    // Inventory Manager
    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };
    const handleOpenIssue = () => {
        setOpenIssue(true);
    };
    const handleCloseIssue = () => {
        setOpenIssue(false);
    };
    const handleOpenCancel = () => {
        setOpenCancel(true);
    };
    const handleCloseCancel = () => {
        setOpenCancel(false);
    };
    const handleOpenTransfer = () => {
        setOpenTransfer(true);
    };
    const handleCloseTransfer = () => {
        setOpenTransfer(false);
    };
    const handleOpenComplete = () => {
        setOpenComplete(true);
    };
    const handleCloseComplete = () => {
        setOpenComplete(false);
    };

    // Division Representative
    const handleOpenReceive = () => {
        setOpenReceive(true);
    };
    const handleCloseReceive = () => {
        setOpenReceive(false);
    };
    const handleOpenReturn = () => {
        setOpenReturn(true);
    };
    const handleCloseReturn = () => {
        setOpenReturn(false);
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const isInvManager = profile ? profile.division && (profile.division.code === "SCM") : false;

    const isRep = profile
        ? profile &&
        profile.division &&
        profile?.division?._id === state.division?._id
        : false;

    const isDivHead = profile
        ? profile &&
        profile.division &&
        profile?.division?.manager === state.division?.manager
        : false;

    const isSolutions = profile ? profile.division && (profile.division.code === "DSD") : false;
    const isQHSE = profile ? profile.division && (profile.division.code === "QHS") : false;

    const forSolutions = isSolutions ?
        ((state.status === "accepted" ||
            state.status === "issued" ||
            state.status === "collected") && state.mainCategory === "IT Equipments")
        : false;

    const forQHSE = isQHSE ?
        ((state.status === "accepted" ||
            state.status === "issued" ||
            state.status === "collected") && state.mainCategory === "PPE")
        : false;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            {/* BD head */}
            <AcceptRequisitionModal {...{ openAccept, handleCloseAccept, id }} />
            <DeclineRequisitionModal {...{ openDecline, handleCloseDecline, id }} />

            {/* Service division head */}
            <ConsentRequisitionModal {...{ openConsent, handleCloseConsent, id }} />
            <RefuseRequisitionModal {...{ openRefuse, handleCloseRefuse, id }} />
            <CollectRequisitionModal {...{ openCollect, handleCloseCollect, id, requisition }} />
            <LeaveRequisitionModal {...{ openLeave, handleCloseLeave, id }} />

            {/* Service division head */}
            <ConfirmRequisitionModal {...{ openConfirm, handleCloseConfirm, id }} />
            <RejectRequisitionModal {...{ openReject, handleCloseReject, id }} />
            <IssueRequisitionModal {...{ openIssue, handleCloseIssue, id, requisition }} />
            <CancelRequisitionModal {...{ openCancel, handleCloseCancel, id }} />
            <TransferRequisitionModal {...{ openTransfer, handleCloseTransfer, id, requisition }} />
            <CompleteRequisitionModal {...{ openComplete, handleCloseComplete, id, requisition }} />

            {/* Commercial division head */}
            <ReceiveRequisitionModal {...{ openReceive, handleCloseReceive, id, requisition }} />
            <ReturnRequisitionModal {...{ openReturn, handleCloseReturn, id, requisition }} />

            <Typography variant="overline" className={classes.title}>
                View Requisition
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/inventory/requisition")}
                    >
                        Back
                    </Button>
                    {isInvManager ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 10 }}
                            className={classes.backBtn}
                            onClick={() => history.push(`/inventory/requisition/export/${id}`)}
                        >
                            Export
                        </Button>
                        ) : null}
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "approved" || state.status === "confirmed" || state.status === "consented") ? classes.statusGreen
                                        : (state.status === "issued" || state.status === "collected" || state.status === "transferred" || state.status === "returned" || state.status === "received" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Inventory Store</label>
                        <Select
                            inputProps={{
                                id: "store",
                                name: "store",
                            }}
                            value={state.store}
                            disabled
                        >
                            <MenuItem disabled>Select Store</MenuItem>
                            {allstores.map((store) => (
                                <MenuItem key={store._id} value={store._id}>
                                    {store.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Date Needed</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={state.dateNeeded || ""}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Requested From</label>
                        <Select
                            inputProps={{
                                id: "locationFrom",
                                name: "locationFrom",
                            }}
                            value={state.locationFrom}
                            disabled
                        >
                            <MenuItem disabled>Select Location</MenuItem>
                            {locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Requested To</label>
                        <Select
                            inputProps={{
                                id: "locationTo",
                                name: "locationTo",
                            }}
                            value={state.locationTo}
                            disabled
                        >
                            <MenuItem disabled>Select Location</MenuItem>
                            {locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label style={{ fontWeight: "bold" }}>Purpose</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled
                        rows={3}
                        name="purpose"
                        value={state.purpose || ""}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl
                        fullWidth
                        required
                        variant="outlined"
                        component="fieldset"
                    >
                        <FormLabel>The category the inventories belong to.</FormLabel>
                        <RadioGroup row>
                            <FormControlLabel
                                value="IT Equipments"
                                control={<Radio checked={state.mainCategory === "IT Equipments" ? true : false} />}
                                label="IT Equipments"
                                name="mainCategory"
                                disabled
                            />
                            <FormControlLabel
                                value="PPE"
                                control={<Radio checked={state.mainCategory === "PPE" ? true : false} />}
                                label="PPE"
                                name="mainCategory"
                                disabled
                            />
                            <FormControlLabel
                                value="Others"
                                control={<Radio checked={state.mainCategory === "Others" ? true : false} />}
                                label="Others"
                                name="mainCategory"
                                disabled
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title2}>
                Inventory Items
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.items &&
                    state.items.map((item, i) => (
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className={classes.grid}
                            key={i}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                    <Select
                                        value={item.item || ""}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        {invItems &&
                                            invItems.map((item) => (
                                                <MenuItem key={item._id} value={item._id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Quantity Needed</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    name="quantityRequested"
                                    value={item.quantityRequested || "1"}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Condition</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    name="condition"
                                    value={item.condition || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>To Be Returned?</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    name="toBeReturned"
                                    value={item.toBeReturned || ""}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    ))}
            </Paper>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={9} />
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Total Quantity Requested"
                        name="totalQuantityRequested"
                        value={state.totalQuantityRequested || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>

            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>
                    {(state.acceptedAt && state.acceptedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Accepted By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.consentedAt && state.consentedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Consented To By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.consentedBy && state.consentedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.consentedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.consentComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.approverComment && state.approvedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Confirmed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approverComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.issuedAt && state.issuedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Issued By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.issuedBy && state.issuedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.issuedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.issueComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.collectedAt && state.collectedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Collected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.collectedBy && state.collectedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.collectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.collectComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.transferredAt && state.transferredBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Transfered By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.transferredBy && state.transferredBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.transferredAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.transferComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.receivedBy && state.receivedAt) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Received By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receivedBy && state.receivedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receivedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receiveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.returnedBy && state.returnedAt) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Returned By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.returnedBy && state.returnedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.returnedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.returnComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.completeComment && state.completedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Completed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completedBy && state.acceptedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completeComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Paper>

            {/* ACTION BUTTONS */}
            {(isAdmin || isDivHead) && state.status === "submitted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This requisition acceptance or decline is to be done by the Head of Subdivison.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenAccept}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Accept
                            </Button>
                            <Button
                                onClick={handleOpenDecline}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Decline
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(forSolutions || forQHSE) && (state.status === "accepted") ? (
                <>
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        The consent or refusal of the availability of the {isSolutions ? "IT Equipments" : (isQHSE ? "PPE" : "items")} is to be done by {isSolutions ? "Solutions" : (isQHSE ? "QHSE" : "the")}  Subdivision.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenConsent}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Consent
                                </Button>
                                <Button
                                    onClick={handleOpenRefuse}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Refuse
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </>
            ) : null}

            {(((isAdmin || isInvManager) && state.status === "consented" && state.mainCategory === "IT Equipments") ||
                ((isAdmin || isInvManager) && state.status === "consented" && state.mainCategory === "PPE") ||
                ((isAdmin || isInvManager) && state.status === "accepted" && state.mainCategory === "Others")) ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This requisition confirmation or rejection is to be done by the Inventory Officer.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenConfirm}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Confirm
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {((isAdmin || isInvManager) && (state.status === "approved" || state.status === "confirmed")) ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    The issuance of items or cancellation of the requisition is to be done by the Inventory Officer.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenIssue}
                                className={classes.saveBtn2}
                                disabled={state.status !== "approved"}
                                variant="contained"
                            >
                                Issue Item(s)
                            </Button>
                            <Button
                                onClick={handleOpenCancel}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {((forSolutions || forQHSE) && state.status === "issued") ? (
                <>
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        The collection or not collection of the {isSolutions ? "IT Equipments" : (isQHSE ? "PPE" : "items")} from the store is to be done by {isSolutions ? "Solutions" : (isQHSE ? "QHSE" : "the")} Subdivision.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenCollect}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Collect Items
                                </Button>
                                <Button
                                    onClick={handleOpenLeave}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Not Collect Items
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </>
            ) : null}

            {((forSolutions || forQHSE) && state.status === "collected") ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    The Transfer (hand over) of the items to the requestor is to be done by the
                                    {isSolutions ? "Solutions" : (isQHSE ? "QHSE" : null)} Subdivision.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenTransfer}
                                className={classes.saveBtn2}
                                disabled={state.status !== "collected"}
                                variant="contained"
                            >
                                Transfer Item(s)
                            </Button>
                            <Button
                                onClick={handleOpenCancel}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Cancel Requisition
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(isAdmin || isRep) && ((state.mainCategory === "IT Equipments" && (state.status === "transferred" || state.status === "received")) ||
                (state.mainCategory === "PPE" && (state.status === "transferred" || state.status === "received")) ||
                (state.mainCategory === "Others" && (state.status === "issued" || state.status === "received"))) ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    These actions are to be done by the Subdivision Representative.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenReceive}
                                className={classes.saveBtn2}
                                color="secondary"
                                disabled={(state.mainCategory === "Others" && state.status !== "issued") ||
                                    ((state.mainCategory === "IT Equipments" || state.mainCategory === "PPE") &&
                                        state.status !== "transferred")}
                                variant="contained"
                            >
                                Receive Item(s)
                            </Button>
                            <Button
                                onClick={handleOpenReturn}
                                className={classes.saveBtn2}
                                color="secondary"
                                disabled={state.status !== "received"}
                                variant="contained"
                            >
                                Return Item(s)
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {((isAdmin || isInvManager) && (state.status === "received" || state.status === "returned")) ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This validates that the Requisition has been completed and is to be done by the Inventory Officer.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenComplete}
                                className={classes.saveBtn2}
                                color="primary"
                                disabled={(state.status !== "returned" || state.status !== "received")}
                                variant="contained"
                            >
                                Completed
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewRequisition;