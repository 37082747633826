import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid,
    makeStyles,
    Paper,
    Tabs,
    Backdrop,
    Tab,
    AppBar,
    TextField,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slide,
    Dialog,
    Toolbar,
    Tooltip,
    Badge,
    IconButton,
    FormLabel,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../../../../utils";
import { connect, useDispatch } from "react-redux";
import VendorDocModal from "./VendorDocModal";

import {
  getVendorById,
  getProductCategories,
  getServiceCategories,
  updateVendor,
  uploadDocument,
} from "../../../../actions/vendorsActions";
import Feedback from "../../../atoms/Feedback";
import { TabPanel } from "../../../Utils";
import { getCurrencies } from "../../../../actions/currencyActions";
import { standards } from "../../../../utils/standards";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    // minHeight: "100vh",
    height: "100%",
    overflowY: "scroll",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },
  gridForm: {
    padding: theme.spacing(3),
    backgroundImage:
      "radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255))",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gridCarousel: {
    display: "block",
    background: "#263238",
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: 0,
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(4, 3, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    boxShadow: "0px 5px 10px #888888",
  },
  paper: {
    padding: theme.spacing(6),
    // marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  carousel1: {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // minHeight: "100vh",
    height: "100%",
    overflow: "scroll",

    // objectFit: "cover",
  },
  btnGroup: {
    marginTop: 40,
  },
  footerBottom: {
    marginTop: "2.5rem",
    textAlign: "center",
  },
  btn2: {
    marginTop: 20,
  },
  footerText: {
    color: "#bdbdbd",
  },
  ref: {
    fontWeight: 800,
    color: "#444",
    fontSize: 14,
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // willChange: "opacity",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25))",
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  tabs: {
    fontSize: 12,
    display: "block",
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const INIT_STATE = {
  companyName: "",
  supplierId:"",
  officeAddress: "",
  registrationNumber: "",
  email: "",
  city: "",
  state: "",
  companyTelephone: "",
  website: "",
  contactPerson: "",
  contactDesignation: "",
  contactTelephone: "",
  contactEmail: "",
  
  businessType: "",
  yearEstablished: "",
  numberOfEmployee: "",
  businessCategory: "",
  relatedProduct: "",
  relatedService: "",
  tin: "",
  vat: "",

  BQMS: "",
  BQMSDocumentNo: "",
  BQMSDocumentUrl:"",
  standardCompliance: "",
  standard: "",
  otherStandard:"",

  QHSPolicy: "",
  policyDocumentName: "",
  QHSDocumentUrl:"",

  QHSRisks: "",
  riskDocumentName: "",
  riskDocumentUrl:"",

  QHSEPolicyAderence: "",
  QHSTraining: "",
  safetyRep: "",
  
  accountName: "",
  accountNumber: "",
  bank: "",
  sortCode: "",
  branch: "",
  currency: "",
  bankContactPhone: "",

  workRefCompanyName: "",
  workRefCompanyAddress: "",
  workRefContactPerson: "",
  workRefContactDesignation: "",
  workRefContactEmail: "",
  workRefContactPhone: "",
  individualRefName: "",
  individualRefAddress: "",
  individualRefEmail: "",
  individualRefPhone: "",
};

const EditVendorModal = ({
    openEdit,
    handleCloseEdit,
    id, 
    vendor,
    getVendorById,
    getProductCategories,
    getServiceCategories,
    getCurrencies,
    currencies,
    products,
    services,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [tabPage, setTabPage] = useState(0);
  const [country, setCountry] = useState(null);
  const [currency, setCurrency] = React.useState(null);
  const [BQMSDocumentFile, setBQMSDocumentFile] = useState(undefined);
  const [QHSDocumentFile, setQHSDocumentFile] = useState(undefined);
  const [riskDocumentFile, setRiskDocumentFile] = useState(undefined);
  const [theUrl, setTheUrl] = useState("");
  const [openDoc, setOpenDoc] = useState(false);
  
  // console.log(id)

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getVendorByIdCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getVendorById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getVendorById]);

  React.useEffect(() => {
    getVendorByIdCallback();
  }, [getVendorByIdCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      companyName: vendor && vendor.companyName,
      supplierId: vendor && vendor.supplierId,
      officeAddress: vendor && vendor.officeAddress,
      registrationNumber: vendor && vendor.registrationNumber,
      email: vendor && vendor.email,
      city: vendor && vendor.city,
      state: vendor && vendor.state,
      country: vendor && vendor.country,
      companyTelephone: vendor && vendor.companyTelephone,
      website: vendor && vendor.website,
      contactPerson: vendor && vendor.contactPerson,
      contactDesignation: vendor && vendor.contactDesignation,
      contactTelephone: vendor && vendor.contactTelephone,
      contactEmail: vendor && vendor.contactEmail,

      businessCategory: vendor && vendor.businessCategory,
      businessType: vendor && vendor.businessType,
      yearEstablished: vendor && vendor.yearEstablished,
      numberOfEmployee: vendor && vendor.numberOfEmployee,
      relatedProduct: vendor && vendor.relatedProduct,
      relatedService: vendor && vendor.relatedService,
      tin: vendor && vendor.tin,
      vat: vendor && vendor.vat,

      BQMS: (vendor && vendor.BQMS) || false,
      BQMSDocumentNo: vendor && vendor.BQMSDocumentNo,
      BQMSDocumentUrl: (vendor && vendor.BQMSDocumentUrl) || null,
      
      standardCompliance: (vendor && vendor.standardCompliance) || false,
      standard: vendor && vendor.standard,
      otherStandard: vendor && vendor.otherStandard,
      QHSPolicy: (vendor && vendor.QHSPolicy) || false,
      policyDocumentName: vendor && vendor.policyDocumentName,
      QHSDocumentUrl: (vendor && vendor.QHSDocumentUrl) || null,

      QHSRisks: (vendor && vendor.QHSRisks) || false,
      riskDocumentName: vendor && vendor.riskDocumentName,
      riskDocumentUrl: (vendor && vendor.riskDocumentUrl) || null,

      QHSEPolicyAderence: (vendor && vendor.QHSEPolicyAderence) || false,
      QHSTraining: (vendor && vendor.QHSTraining) || false,
      safetyRep: (vendor && vendor.safetyRep) || false,

      accountName: vendor && vendor.accountName,
      accountNumber: vendor && vendor.accountNumber,
      bank: vendor && vendor.bank,
      sortCode: vendor && vendor.sortCode,
      currency: vendor && vendor.currency,
      branch: vendor && vendor.branch,
      bankContactPhone: vendor && vendor.bankContactPhone,

      workRefCompanyName: vendor && vendor.workRefCompanyName,
      workRefCompanyAddress: vendor && vendor.workRefCompanyAddress,
      workRefContactPerson: vendor && vendor.workRefContactPerson,
      workRefContactDesignation: vendor && vendor.workRefContactDesignation,
      workRefContactEmail: vendor && vendor.workRefContactEmail,
      workRefContactPhone: vendor && vendor.workRefContactPhone,
      individualRefName: vendor && vendor.individualRefName,
      individualRefAddress: vendor && vendor.individualRefAddress,
      individualRefEmail: vendor && vendor.individualRefEmail,
      individualRefPhone: vendor && vendor.individualRefPhone,
    }));
  }, [vendor]);

  React.useEffect(() => {
    setLoading((prev) => !prev);
    getCurrencies().then(() => {
      setLoading(false);
    });
  }, [getCurrencies]);

  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.name,
    renderOption: (option) => (
      <React.Fragment>
        {option.name} - {option.code}
      </React.Fragment>
    ),
  };

  React.useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  React.useEffect(() => {
    getServiceCategories();
  }, [getServiceCategories]);

  const handleTabChange = (e, newValue) => {
    setTabPage(newValue);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCountry = (e, country) => {
    setCountry(country);
  };
  
  const handleUploadBQMSDocument = (e) => {
    const { files } = e.target;
    setBQMSDocumentFile(files[0]);
  };

  const handleUploadQHSDocument = (e) => {
    const { files } = e.target;
    setQHSDocumentFile(files[0]);
  };

  const handleUploadRiskDocument = (e) => {
    const { files } = e.target;
    setRiskDocumentFile(files[0]);
  };
  
  useEffect(() => {
    const upload = async () => {
      if (BQMSDocumentFile) {
          const formData = new FormData();
          formData.append("doc", BQMSDocumentFile);
          let url = await new Promise((resolve, reject) => {
            resolve(dispatch(uploadDocument(formData)));
          });

          if (url) {
            setState((prev) => ({
              ...prev,
              BQMSDocumentUrl: url,
            }));

            setBQMSDocumentFile(null);
          }
      }
    };
    upload();
  }, [BQMSDocumentFile, dispatch]);

  useEffect(() => {
    const upload = async () => {
      if (QHSDocumentFile) {
        const formData = new FormData();
        formData.append("doc", QHSDocumentFile);
        let url = await new Promise((resolve, reject) => {
          resolve(dispatch(uploadDocument(formData)));
        });

        if (url) {
          setState((prev) => ({
            ...prev,
            QHSDocumentUrl: url,
          }));

          setQHSDocumentFile(null);
        }
      }
    };
    upload();
  }, [QHSDocumentFile, dispatch]);

  useEffect(() => {
    const upload = async () => {
      if (riskDocumentFile) {
        const formData = new FormData();
        formData.append("doc", riskDocumentFile);
        let url = await new Promise((resolve, reject) => {
          resolve(dispatch(uploadDocument(formData)));
        });

        if (url) {
          setState((prev) => ({
            ...prev,
            riskDocumentUrl: url,
          }));

          setRiskDocumentFile(null);
        }
      }
    };
    upload();
  }, [riskDocumentFile, dispatch]);
  
  const validCheck1 = () => {
    return (
      !state.companyName ||
      !state.officeAddress ||
      !state.email ||
      !state.city ||
      !state.state ||
      !country ||
      !state.companyTelephone ||
      !state.website ||
      !state.contactPerson ||
      !state.contactDesignation ||
      !state.contactTelephone ||
      !state.contactEmail
    );
  };

  const handleSubmitInfo = async (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      companyName: state.companyName,
      officeAddress: state && state.officeAddress,
      email: state && state.email,
      city: state && state.city,
      state: state && state.state,
      country: country && country.label,
      companyTelephone: state && state.companyTelephone,
      website: state && state.website,
      contactPerson: state && state.contactPerson,
      contactDesignation: state && state.contactDesignation,
      contactTelephone: state && state.contactTelephone,
      contactEmail: state && state.contactEmail,
      id,
    };

    if(data.companyName && data.officeAddress && data.email && data.city && data.state && data.country && data.companyTelephone && data.contactPerson){
      setOpenBackdrop((prev) => !prev);
      setLoading((prev) => !prev);

      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateVendor(data)));
      });
          
      if (res && res.success) {
        setOpenBackdrop(false);
        setLoading(false);
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Vendor General Info updated successfully",
          success: true,
        }));

        setTimeout(() => {
          handleCloseEdit();
        }, 2000);
      }
    }
  };
  
  const validCheck2 = () => {
    return (
      !state.businessType ||
      !state.supplierId ||
      !state.yearEstablished ||
      !state.numberOfEmployee ||
      !state.businessCategory ||
      !state.registrationNumber ||
      !state.tin ||
      !state.vat
    );
  };

  const handleSubmitBusinessInfo = async (e) => {
    e.preventDefault();
    const data = {
      supplierId: state.supplierId,
      businessType: state && state.businessType,
      numberOfEmployee: state && state.numberOfEmployee,
      yearEstablished: state && state.yearEstablished,
      registrationNumber: state && state.registrationNumber,
      businessCategory: state && state.businessCategory,
      relatedProduct: state && state.relatedProduct,
      relatedService: state && state.relatedService,
      tin: state && state.tin,
      vat: state && state.vat,
      id,
    };
    
    if(data.supplierId && data.businessCategory && data.businessType && data.numberOfEmployee && data.yearEstablished && data.tin && data.vat){
      setOpenBackdrop((prev) => !prev);
      setLoading((prev) => !prev);

      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateVendor(data)));
      });
          
      if (res && res.success) {
        setOpenBackdrop(false);
        setLoading(false);
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Vendor business info updated successfully",
          success: true,
        }));

        setTimeout(() => {
          handleCloseEdit();
        }, 2000);
      }
    }
  };


  const validCheck3 = () => {
    return (
      !state.safetyRep ||
      !state.QHSEPolicyAderence ||
      !state.QHSTraining
    );
  };

  const handleSubmitQHSEInfo = async (e) => {
    e.preventDefault();
    const data = {
      BQMS: state && state.BQMS,
      BQMSDocumentNo: state && state.BQMSDocumentNo,
      BQMSDocumentUrl: state && state.BQMSDocumentUrl,
      standardCompliance: state && state.standardCompliance,
      standard: state && state.standard,
      otherStandard: state && state.otherStandard,

      QHSPolicy: state && state.QHSPolicy,
      policyDocumentName: state && state.policyDocumentName,
      QHSDocumentUrl: state && state.QHSDocumentUrl,

      QHSRisks: state && state.QHSRisks,
      riskDocumentName: state && state.riskDocumentName,
      riskDocumentUrl: state && state.riskDocumentUrl,

      QHSEPolicyAderence: state && state.QHSEPolicyAderence,
      QHSTraining: state && state.QHSTraining,
      safetyRep: state && state.safetyRep,
      id,
    };
    
    if(data.QHSEPolicyAderence && data.id){
      setOpenBackdrop((prev) => !prev);
      setLoading((prev) => !prev);

      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateVendor(data)));
      });
          
      if (res && res.success) {
        setOpenBackdrop(false);
        setLoading(false);
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Vendor QHSE Info updated successfully",
          success: true,
        }));

        setTimeout(() => {
          handleCloseEdit();
        }, 2000);
      }
    }
  };

  const validCheck4 = () => {
    return (
        !state.accountName ||
        !state.accountNumber ||
        !state.bank ||
        !state.branch ||
        !currency
      );
  };

  const handleSubmitBankInfo = async (e) => {
    e.preventDefault();
    const data = {
      accountName: state && state.accountName,
      accountNumber: state && state.accountNumber,
      bank: state && state.bank,
      sortCode: state && state.sortCode,
      currency: currency && currency.code,
      branch: state && state.branch,
      bankContactPhone: state && state.bankContactPhone,
      id,
    };
    
    if(data.accountName && data.accountNumber && data.bank && data.currency && data.branch && data.id){
      setOpenBackdrop((prev) => !prev);
      setLoading((prev) => !prev);

      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateVendor(data)));
      });
          
      if (res && res.success) {
        setOpenBackdrop(false);
        setLoading(false);
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Vendor Bank Info updated successfully",
          success: true,
        }));

        setTimeout(() => {
          handleCloseEdit();
        }, 2000);
      }
    }
  };


  const validCheck5 = () => {
    return (
      !state.workRefCompanyAddress ||
      !state.workRefContactPhone ||
      !state.workRefCompanyName ||
      !state.workRefContactDesignation ||
      !state.workRefContactEmail ||
      !state.workRefContactPerson ||
      !state.individualRefEmail ||
      !state.individualRefName ||
      !state.individualRefPhone ||
      !state.individualRefAddress
    );
  };

  const handleSubmitRefInfo = async (e) => {
    e.preventDefault();
    const data = {
      workRefCompanyName: state && state.workRefCompanyName,
      workRefCompanyAddress: state && state.workRefCompanyAddress,
      workRefContactDesignation: state && state.workRefContactDesignation,
      workRefContactEmail: state && state.workRefContactEmail,
      workRefContactPerson: state && state.workRefContactPerson,
      workRefContactPhone: state && state.workRefContactPhone,
      individualRefName: state && state.individualRefName,
      individualRefAddress: state && state.individualRefAddress,
      individualRefEmail: state && state.individualRefEmail,
      individualRefPhone: state && state.individualRefPhone,
      id,
    };
    
    if(data.workRefCompanyName && data.workRefCompanyAddress && data.workRefContactDesignation && data.workRefContactEmail && 
      data.workRefContactPerson && data.workRefContactPhone && data.individualRefName && data.individualRefAddress && 
      data.individualRefEmail && data.individualRefPhone && data.id){
      setOpenBackdrop((prev) => !prev);
      setLoading((prev) => !prev);

      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateVendor(data)));
      });
          
      if (res && res.success) {
        setOpenBackdrop(false);
        setLoading(false);
        setFeed((prev) => ({
          loading: false,
          open: true,
          message: "Vendor Work Reference Info updated successfully",
          success: true,
        }));

        setTimeout(() => {
          handleCloseEdit();
        }, 2000);
      }
    }
  };

  const handleOpenDoc = (url) => {
    setTheUrl(null);
    if(url){
      setTheUrl(url);
      setOpenDoc(true);
    }
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <VendorDocModal
        {...{ openDoc, handleCloseDoc, docs: theUrl }}
      />

      <Dialog
        fullScreen
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Typography className={classes.title} variant="overline" gutterBottom>
            Edit Vendor Details
          </Typography>

          <AppBar position="static" color="transparent">
            <Tabs
              value={tabPage}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              centered
            >
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    General Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(0)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Business Info
                  </Typography>
                }
                wrapped
                {...a11yProps(1)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    QHSE Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(2)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Bank Details
                  </Typography>
                }
                wrapped
                {...a11yProps(3)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Work Ref.
                  </Typography>
                }
                wrapped
                {...a11yProps(4)}
                className={classes.tabs}
                fullWidth
              />
            </Tabs>
          </AppBar>
            <Paper className={classes.paper} variant="outlined">
              <TabPanel value={tabPage} index={0}>
                <form onSubmit={handleSubmitInfo}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        type="email"
                        name="email"
                        label="Email"
                        onChange={handleChange}
                        value={state.email || ""}
                        fullWidth
                        required
                        placeholder="Email"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="companyName"
                        name="companyName"
                        label="Company Name"
                        onChange={handleChange}
                        value={state.companyName || ""}
                        fullWidth
                        required
                        placeholder="Company Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="officeAddress"
                        name="officeAddress"
                        label="Office Address"
                        onChange={handleChange}
                        value={state.officeAddress || ""}
                        fullWidth
                        required
                        placeholder="Office Address"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="city"
                        name="city"
                        label="City"
                        value={state.city || ""}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder="City"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="state"
                        name="state"
                        label="State"
                        value={state.state || ""}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder="State"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={countries}
                        value={(country) || ""}
                        name="country"
                        onChange={handleCountry}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <React.Fragment>{option.label}</React.Fragment>
                        )}
                        autoComplete
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled",
                              name: "country",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="companyTelephone"
                        name="companyTelephone"
                        label="Company Telephone"
                        fullWidth
                        required
                        value={state.companyTelephone || ""}
                        onChange={handleChange}
                        placeholder="Company Telephone"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="website"
                        name="website"
                        label="Website"
                        fullWidth
                        required
                        value={state.website || ""}
                        onChange={handleChange}
                        placeholder="Website"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="contactPerson"
                        name="contactPerson"
                        label="Contact Person"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={state.contactPerson || ""}
                        placeholder="Contact Person"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="contactDesignation"
                        name="contactDesignation"
                        label="Designation"
                        fullWidth
                        required
                        placeholder="Designation"
                        value={state.contactDesignation || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="contactTelephone"
                        name="contactTelephone"
                        label="Contact Telephone"
                        fullWidth
                        required
                        placeholder="Contact Telephone"
                        value={state.contactTelephone || ""}
                        onChange={handleChange}
                        type="tel"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        type="email"
                        id="contactEmail"
                        name="contactEmail"
                        label="Contact Email"
                        fullWidth
                        placeholder="Contact Email"
                        value={state.contactEmail || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          className={classes.saveBtn2}
                          disabled={validCheck1() || loading}
                          size="large"
                          endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                          {loading ? "Loading..." : "Save Changes"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>


              <TabPanel value={tabPage} index={1}>
                <form onSubmit={handleSubmitBusinessInfo}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="demo-controlled-open-select-label">
                          Business Type
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="businessType"
                          name="businessType"
                          inputProps={{
                            id: "businessType",
                            name: "businessType",
                          }}
                          value={state.businessType || ""}
                          onChange={handleChange}
                        >
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Corporate Limited">
                            Corporate Limited
                          </MenuItem>
                          <MenuItem value="Partnership">Partnership</MenuItem>
                          <MenuItem value="Sole Proprietorship">
                            Sole Proprietorship
                          </MenuItem>
                          {/* <MenuItem value="Others">Others</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="yearEstablished"
                        name="yearEstablished"
                        label="Year Established"
                        fullWidth
                        required
                        placeholder="Year Established"
                        value={state.yearEstablished || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="numberOfEmployee"
                        name="numberOfEmployee"
                        label="No. of Employee"
                        fullWidth
                        required
                        placeholder="No. of Employee"
                        value={state.numberOfEmployee || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="registrationNumber"
                        name="registrationNumber"
                        label="Registration/CAC Number"
                        value={state.registrationNumber || ""}
                        onChange={handleChange}
                        fullWidth
                        required={
                          state.businessType === "Sole Proprietorship"
                            ? false
                            : true
                        }
                        placeholder="Registration Number"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="business_category">
                          Business Category
                        </InputLabel>
                        <Select
                          labelId="business_category"
                          id="businessCategory"
                          name="businessCategory"
                          inputProps={{
                            id: "businessCategory",
                            name: "businessCategory",
                          }}
                          value={state.businessCategory || ""}
                          onChange={handleChange}
                        >
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="products">Products</MenuItem>
                          <MenuItem value="services">Services</MenuItem>
                          <MenuItem value="productsAndServices">
                            Products & Services
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {state.businessCategory === "products" ||
                    state.businessCategory === "productsAndServices" ? (
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="related_product_label">
                            Related Product
                          </InputLabel>
                          <Select
                            labelId="related_product_label"
                            id="relatedProduct"
                            name="relatedProduct"
                            inputProps={{
                              id: "relatedProduct",
                              name: "relatedProduct",
                            }}
                            value={state.relatedProduct || ""}
                            onChange={handleChange}
                          >
                            <MenuItem value="" disabled>
                              <em>None</em>
                            </MenuItem>
                            {products &&
                              products.map((product) => (
                                <MenuItem key={product._id} value={product.name}>
                                  {product.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}

                    {state.businessCategory === "services" ||
                    state.businessCategory === "productsAndServices" ? (
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="related_service_label">
                            Related Service
                          </InputLabel>
                          <Select
                            labelId="related_service_label"
                            id="relatedService"
                            name="relatedService"
                            inputProps={{
                              id: "relatedService",
                              name: "relatedService",
                            }}
                            value={state.relatedService || ""}
                            onChange={handleChange}
                          >
                            {services &&
                              services.map((service) => (
                                <MenuItem key={service._id} value={service.name}>
                                  {service.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="tin"
                        name="tin"
                        label="Tax Identification No."
                        fullWidth
                        required={
                          state.businessType === "Sole Proprietorship"
                            ? false
                            : true
                        }
                        placeholder="Tax Identification No."
                        value={state.tin || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="vat"
                        name="vat"
                        label="VAT Registration No."
                        fullWidth
                        required={
                          state.businessType === "Sole Proprietorship"
                            ? false
                            : true
                        }
                        placeholder="VAT Registration No."
                        value={state.vat || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="supplierId"
                        name="supplierId"
                        label="Supplier Id"
                        onChange={handleChange}
                        value={state.supplierId || ""}
                        fullWidth
                        placeholder="Supplier Id"
                      />
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          className={classes.saveBtn2}
                          disabled={validCheck2() || loading}
                          size="large"
                          endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                          {loading ? "Loading..." : "Save Changes"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>



              <TabPanel value={tabPage} index={2}>
              <form onSubmit={handleSubmitQHSEInfo}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Typography variant="overline" gutterBottom className={classes.ref}>
                      Quality Management System Requirements
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={state.BQMSDocumentUrl === null}
                        onClick={() => handleOpenDoc(state.BQMSDocumentUrl)}
                      >
                        <Badge
                          color="secondary"
                          badgeContent={state.BQMSDocumentUrl ? 1 : null}
                        >
                          <AttachFileIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have any Management System?
                      </FormLabel>

                      <Select
                        labelId="BQMS"
                        id="BQMS"
                        name="BQMS"
                        inputProps={{
                          id: "BQMS",
                          name: "BQMS",
                        }}
                        value={state.BQMS}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {state.BQMS ? ( <>
                    <Grid item xs={12} md={4}>
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> If Yes? Enter Your Business Quality Management Document Number
                      </FormLabel>
                      <TextField
                        variant="outlined"
                        name="BQMSDocumentNo"
                        fullWidth
                        value={state.BQMSDocumentNo}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> If Yes? Upload Your Quality Management System Document
                      </FormLabel>
                      <FormControl fullWidth variant="outlined" required>
                        <input
                          type="file"
                          variant="outlined"
                          accept="image/*,.pdf"
                          id="file"
                          className={classes.input}
                          required={state.BQMS && state.BQMSDocumentUrl === null}
                          onChange={handleUploadBQMSDocument}
                        />
                      </FormControl>
                    </Grid>
                    
                    </> ) : <Grid item xs={12} md={8}></Grid> }


                  <Grid item xs={12} md={12}>
                    {state.BQMS && <Divider light />}
                  </Grid>

                  {state.BQMS && ( <>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        component="fieldset"
                      >
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> Can you confirm that your systems complies with the requirements?
                        </FormLabel>

                        <Select
                          labelId="standardCompliance"
                          id="standardCompliance"
                          name="standardCompliance"
                          inputProps={{
                            id: "standardCompliance",
                            name: "standardCompliance",
                          }}
                          value={state.standardCompliance}
                          onChange={handleChange}
                        >
                          <MenuItem value={false} disabled>
                            <em>----Select Option----</em>
                          </MenuItem>

                          <MenuItem value={false}>No</MenuItem>
                          <MenuItem value={true}>Yes</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {state.standardCompliance ? (
                      <>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined">
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          >
                            <strong>Q.</strong> If Yes? Please state the standard {""} {""}
                          </FormLabel>

                          <Select
                            id="standard"
                            name="standard"
                            inputProps={{
                              id: "standard",
                              name: "standard",
                            }}
                            value={state.standard}
                            onChange={handleChange}
                          >
                            <MenuItem value={false} disabled>
                              <em>----Select Option----</em>
                            </MenuItem>
                            {standards.map((standard, i) => (
                              <MenuItem key={i} value={standard}>
                                {standard}
                              </MenuItem>
                            ))}
                            <MenuItem value="others">Others</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {state.standard === "others" ? (
                        <Grid item xs={12} md={4}>
                          <FormLabel component="legend" className={classes.label}>
                            Please indicate the other standard your company conforms to
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            name="otherStandard"
                            id="otherStandard"
                            fullWidth
                            required={state.standard === "others"}
                            onChange={handleChange}
                          />
                        </Grid>
                      ) : <Grid item xs={12} md={4}></Grid> } 
                      </>
                    ) : <Grid item xs={12} md={8}></Grid> }
                    </>
                  )}

                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Typography variant="overline" gutterBottom className={classes.ref}>
                      Health, Safety & Environment Requirements
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={state.QHSDocumentUrl === null}
                        onClick={() => handleOpenDoc(state.QHSDocumentUrl)}
                      >
                        <Badge
                          color="secondary"
                          badgeContent={state.QHSDocumentUrl ? 1 : null}
                        >
                          <AttachFileIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                  </Grid>

                  {/* </Grid> */}

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have a Quality, Health & Safety Policy?
                      </FormLabel>

                      <Select
                        labelId="QHSPolicy"
                        id="QHSPolicy"
                        name="QHSPolicy"
                        inputProps={{
                          id: "QHSPolicy",
                          name: "QHSPolicy",
                        }}
                        value={state.QHSPolicy}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {state.QHSPolicy ? (
                    <>
                      <Grid item xs={12} md={4}>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Please indicate Document Name
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="policyDocumentName"
                          fullWidth
                          value={state.policyDocumentName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Upload Your Quality, Health & Safety Document
                        </FormLabel>
                        <FormControl fullWidth variant="outlined" required>
                          <input
                            type="file"
                            variant="outlined"
                            accept="image/*,.pdf"
                            id="file"
                            className={classes.input}
                            required={state.QHSPolicy && state.QHSDocumentUrl === null}
                            onChange={handleUploadQHSDocument}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  ) : <Grid item xs={12} md={8}></Grid> }


                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Typography variant="overline" gutterBottom className={classes.ref}>
                        Quality, Health & Safety Risks Compliance
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={state.riskDocumentUrl === null}
                        onClick={() => handleOpenDoc(state.riskDocumentUrl)}
                      >
                        <Badge
                          color="secondary"
                          badgeContent={state.riskDocumentUrl ? 1 : null}
                        >
                          <AttachFileIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                  </Grid>


                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company have Systems to
                        Identify Significant Quality, Health & Safety Risks &
                        How They're Controlled?
                      </FormLabel>

                      <Select
                        labelId="QHSRisks"
                        id="QHSRisks"
                        name="QHSRisks"
                        inputProps={{
                          id: "QHSRisks",
                          name: "QHSRisks",
                        }}
                        value={state.QHSRisks}
                        onChange={handleChange}
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {state.QHSRisks ? (
                    <>
                      <Grid item xs={12} md={4}>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Please indicate Document
                          Name
                          {""}
                          {""}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="riskDocumentName"
                          fullWidth
                          value={state.riskDocumentName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Upload Your Quality, Health & Safety Risks Document
                        </FormLabel>
                        <FormControl fullWidth variant="outlined" required>
                          <input
                            type="file"
                            variant="outlined"
                            accept="image/*,.pdf"
                            id="file"
                            className={classes.input}
                            required={state.QHSRisks && state.riskDocumentUrl === null}
                            onChange={handleUploadRiskDocument}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  ) : <Grid item xs={12} md={8}></Grid> }


                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Please Confirm that you'll adhere to
                        both RusselSmith's QHSE Policies and Where applicable,
                        comply with any project specific requirements requested
                        by Our Site Management to ensure the Safety of everyone
                        on Site is Maintained?
                      </FormLabel>

                      <Select
                        labelId="QHSEPolicyAderence"
                        id="QHSEPolicyAderence"
                        name="QHSEPolicyAderence"
                        inputProps={{
                          id: "QHSEPolicyAderence",
                          name: "QHSEPolicyAderence",
                        }}
                        value={state.QHSEPolicyAderence}
                        onChange={handleChange}
                      >
                        <MenuItem disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Does your company encourage and
                        provide proactive health and safety training?
                      </FormLabel>

                      <Select
                        labelId="QHSTraining"
                        id="QHSTraining"
                        name="QHSTraining"
                        inputProps={{
                          id: "QHSTraining",
                          name: "QHSTraining",
                        }}
                        value={state.QHSTraining}
                        onChange={handleChange}
                      >
                        <MenuItem disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      required
                      component="fieldset"
                      style={{ marginTop: 20 }}
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Do you have appointed safety
                        representatives within your work force?
                      </FormLabel>

                      <Select
                        labelId="safetyRep"
                        id="safetyRep"
                        name="safetyRep"
                        inputProps={{
                          id: "safetyRep",
                          name: "safetyRep",
                        }}
                        value={state.safetyRep}
                        onChange={handleChange}
                      >
                        <MenuItem disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          className={classes.saveBtn2}
                          disabled={validCheck3() || loading || openBackdrop}
                          size="large"
                          endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                          {loading ? "Loading..." : "Save Changes"}
                        </Button>
                      </Grid>
                    </Grid>
                </Grid>
                </form>
              </TabPanel>



              <TabPanel value={tabPage} index={3}>
                <form onSubmit={handleSubmitBankInfo}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="accountName"
                        name="accountName"
                        label="Account Name"
                        fullWidth
                        required
                        placeholder="Account Name"
                        value={state.accountName || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="accountNumber"
                        name="accountNumber"
                        label="Account Number"
                        fullWidth
                        required
                        placeholder="Account Number"
                        value={state.accountNumber || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="bank"
                        name="bank"
                        label="Bank"
                        fullWidth
                        required
                        placeholder="Bank"
                        value={state.bank || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="sortCode"
                        name="sortCode"
                        label="Sort/Swift Code"
                        fullWidth
                        placeholder="Sort/Swift Code"
                        value={state.sortCode || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        {...defaultProps}
                        name="name"
                        value={currency}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>
                              {option.name} - {option.code}
                            </span>
                          </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                          setCurrency(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Currency"
                            variant="outlined"
                            value={currency}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="branch"
                        name="branch"
                        label="Branch"
                        fullWidth
                        required
                        placeholder="Branch"
                        value={state.branch || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="bankContactPhone"
                        name="bankContactPhone"
                        label="Contact Phone"
                        fullWidth
                        placeholder="Contact Phone"
                        value={state.bankContactPhone || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                          className={classes.saveBtn2}
                          disabled={validCheck4() || loading || openBackdrop}
                          size="large"
                          endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                          {loading ? "Loading..." : "Save Changes"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>



              <TabPanel value={tabPage} index={4}>
                <form onSubmit={handleSubmitRefInfo}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="workRefCompanyName"
                        name="workRefCompanyName"
                        label="Company Name"
                        fullWidth
                        required
                        placeholder="Company Name"
                        value={state.workRefCompanyName || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="workRefCompanyAddress"
                        name="workRefCompanyAddress"
                        label="Company Address"
                        fullWidth
                        required
                        placeholder="Company Address"
                        value={state.workRefCompanyAddress || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="workRefContactPerson"
                        name="workRefContactPerson"
                        label="Contact Person"
                        fullWidth
                        required
                        placeholder="Contact Person"
                        value={state.workRefContactPerson || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="workRefContactDesignation"
                        name="workRefContactDesignation"
                        label="Contact Designation"
                        fullWidth
                        required
                        placeholder="Contact Designation"
                        value={state.workRefContactDesignation || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        type="email"
                        id="workRefContactEmail"
                        name="workRefContactEmail"
                        label="Contact Email"
                        fullWidth
                        required
                        placeholder="Contact Email"
                        value={state.workRefContactEmail || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="workRefContactPhone"
                        name="workRefContactPhone"
                        label="Contact Phone"
                        fullWidth
                        required
                        placeholder="Contact Phone"
                        value={state.workRefContactPhone || ""}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography variant="overline" className={classes.ref}>
                        Individual Reference
                      </Typography>

                      <Divider light />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="individualRefName"
                        name="individualRefName"
                        label="Name"
                        fullWidth
                        required
                        placeholder="Name"
                        value={state.individualRefName || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="individualRefAddress"
                        name="individualRefAddress"
                        label="Address"
                        fullWidth
                        required
                        placeholder="Address"
                        value={state.individualRefAddress || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        type="email"
                        id="individualRefEmail"
                        name="individualRefEmail"
                        label="Email"
                        fullWidth
                        required
                        placeholder="Email"
                        value={state.individualRefEmail || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="individualRefPhone"
                        name="individualRefPhone"
                        label="Phone"
                        fullWidth
                        required
                        placeholder="Phone"
                        value={state.individualRefPhone || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.saveBtn2}
                            disabled={validCheck5() || loading}
                            size="large"
                            endIcon={loading ? <CircularProgress size={20} /> : null}
                          >
                            {loading ? "Loading..." : "Save Changes"}
                          </Button>
                        </Grid>
                      </Grid>
                  </Grid>
                </form>
              </TabPanel>
            </Paper>
        </div>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openBackdrop || loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  profile: state.user.profile,  
  products: state.vendors.products,
  services: state.vendors.services,
  currencies: state.currency.currencies,
});

const mapDispatchToProps = { getVendorById, getProductCategories, getServiceCategories, getCurrencies };

export default connect(mapStateToProps, mapDispatchToProps)(EditVendorModal);
