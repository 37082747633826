import React, { useState } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Menu,
  Select,
  Button,
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

import SearchComponent from "../../SearchComponent";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";

import { getDivisions, createDivision } from "../../../actions/divisionActions";
import Feedback from "../../atoms/Feedback";
import { getUsers } from "../../../actions/userActions";
import EditDivisionModal from "./modals/EditDivisionModal";
import { TablePaginationActions } from "../../TablePaginationActions";
import DeleteDivisionModal from "./modals/DeleteDivisionModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  marginBottom: {
    marginBottom: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  statusGreen: {
      borderColor: "#1de9b6",
  },
  statusOrange: {
      borderColor: "#ff9800",
  },
}));

const INIT_STATE = {
  name: "",
  parent: "",
  code: "",
  groupEmail:"",
  manager: "",
  head: "",
  status:"",
};

const CreateDivision = ({
  createDivision,
  getDivisions,
  error,
  divisions,
  users,
  getUsers,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(INIT_STATE);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [getId, setGetId] = useState("");
  
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const dispatch = useDispatch();

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getDivisionsAndUsersCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);

    getDivisions().then((doc) => {
      getUsers();

      setOpenBackdrop(false);
    });
  }, [getUsers, getDivisions]);

  React.useEffect(() => {
    getDivisionsAndUsersCallback();
  }, [getDivisionsAndUsersCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();

    setSearch("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (state.parent || state.manager) {
      data.parent = state.parent || undefined;
      data.manager = state.manager || undefined;
    }
    data.name = state.name;
    data.head = state.head;
    data.code = state.code.toUpperCase();
    data.groupEmail = state.groupEmail;

    setLoading(!loading);
    setOpenBackdrop(!openBackdrop);
    createDivision(data)
      .then(() => {
        setLoading(false);
        setState(INIT_STATE);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Division created Successfully`,
          success: true,
        });
        setOpenBackdrop(false);
        setOpen(false);
        dispatch(getDivisions);
      })
      .catch((err) => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: error.error,
          success: false,
        });
        setOpen(false);

        console.error(err);
      });
  };

  const validCheck = () => {
    return !state.name || !state.code || !state.head;
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const filteredRows = () =>
    divisions === undefined
      ? []
      : divisions.filter((row) => {
          if (search !== "") {
            return (
              (row && row.name && row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row.code && row.code.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row && row.type && row.type.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row && row.status && row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row.parent && row.parent.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (row.manager &&
                row.manager.userName &&
                row.manager.userName
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.head &&
                row.head.userName
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1)
            );
          } else if (filter !== "") {
            return row.type.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
          } else {
            return row;
          }
        });

  const dataLength = Array(1000).length || filteredRows().length;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

    const handleSelectFilter = (e, val) => {
      setFilter(val.substr(0, 20));
      handleCloseFilter();
    };
    const handleOpenFilter = (event) => {
      setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
      setFilterAnchorEl(null);
    };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <EditDivisionModal
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        id={getId}
        divisions={divisions}
        // getDivisions={getDivisions}
        users={users}
      />

      <DeleteDivisionModal {...{ openDelete, handleCloseDelete, id: getId }} />

      <Typography variant="overline" className={classes.title}>
        Divisions / Units
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            // style={{ marginRight: 5 }}
            onClick={handleClickOpen}
            className={classes.btn}
          >
            Create
          </Button>
        </Grid>

        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
                <IconButton
                    style={{ marginRight: 10, marginTop: 10 }}
                    onClick={handleOpenFilter}
                >
                    <FilterListIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={filterAnchorEl}
                    keepMounted
                    open={Boolean(filterAnchorEl)}
                    onClose={handleCloseFilter}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                        All
                    </MenuItem>
                    <MenuItem onClick={(e) => handleSelectFilter(e, "division")}>
                        Division
                    </MenuItem>
                    <MenuItem onClick={(e) => handleSelectFilter(e, "unit")}>
                        Unit
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Group Email</TableCell>
                <TableCell>Parent</TableCell>
                <TableCell>Manager</TableCell>
                <TableCell>Head</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>
                      {row.groupEmail ? (
                        row.groupEmail
                      ) : (
                        <Chip label="Not Available" disabled />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.parent ? (
                        row.parent?.name
                      ) : (
                        <Chip label="Nil" disabled />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.manager ? (
                        row.manager.userName
                      ) : (
                        <Chip label="Not Available" disabled />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.head ? (
                        row.head.userName
                      ) : (
                        <Chip label="Not Available" disabled />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.type ? (
                        row.type
                      ) : (
                        <Chip label="Not Available" disabled />
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        style={{ padding: 10 }}
                        label={row.status}
                        variant="outlined" 
                        className={clsx(row.status === "active" ? classes.statusGreen : classes.statusOrange, classes.chip)}
                      />
                    </TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEdit(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ color: "#616161" }}
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Division / Unit
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={state.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="division-label-id">Parent</InputLabel>
                  <Select
                    labelId="division-label-id"
                    id="parent"
                    inputProps={{
                      id: "parent",
                      name: "parent",
                    }}
                    label="Parent"
                    name="parent"
                    value={state.parent || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      ---Select Division----
                    </MenuItem>
                    {loading ? (
                      <MenuItem value="" disabled>
                        Loading
                      </MenuItem>
                    ) : (
                      divisions &&
                      divisions.map((division) => (
                        <MenuItem key={division._id} value={division._id}>
                          {division.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="code"
                  name="code"
                  label="Code"
                  value={state.code}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="groupEmail"
                  name="groupEmail"
                  label="Group Email"
                  value={state.groupEmail}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="manager-id">Manager</InputLabel>
                  <Select
                    labelId="manager-id"
                    id="manager"
                    inputProps={{
                      id: "manager",
                      name: "manager",
                    }}
                    label="Manager"
                    name="mananger"
                    value={state.manager || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      ---Select Manager----
                    </MenuItem>
                    <MenuItem value={undefined}>None</MenuItem>
                    {loading ? (
                      <MenuItem value="" disabled>
                        Loading
                      </MenuItem>
                    ) : (
                      users &&
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.userName}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="head-id">Head</InputLabel>
                  <Select
                    labelId="head-id"
                    id="head"
                    inputProps={{
                      id: "head",
                      name: "head",
                    }}
                    label="Head"
                    name="head"
                    value={state.head || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      ----Select Head----
                    </MenuItem>
                    <MenuItem value={undefined}>None</MenuItem>

                    {loading ? (
                      <MenuItem value="" disabled>
                        Loading
                      </MenuItem>
                    ) : (
                      users &&
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.userName}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                    <InputLabel id="type">
                        Type
                    </InputLabel>
                    <Select
                        labelId="type"
                        required
                        value={state.type || ""}
                        name="type"
                        onChange={handleChange}
                    >
                        <MenuItem disabled>Select</MenuItem>
                        <MenuItem value="division">Division</MenuItem>
                        <MenuItem value="unit">Unit</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                    <InputLabel id="status">
                        Status
                    </InputLabel>
                    <Select
                        labelId="status"
                        required
                        value={state.status || ""}
                        name="status"
                        onChange={handleChange}
                    >
                        <MenuItem disabled>Select</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                className={classes.saveBtn}
                disabled={validCheck() || loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  divisions: state.division.divisions,

  users: state.user.users,
});

export default connect(mapStateToProps, {
  getDivisions,
  createDivision,
  getUsers,
})(CreateDivision);
